import "./StatusBubble.scss";

import { Icon } from "@fluentui/react";

export interface StatusBubbleProps {
	label: string;
	iconName: string;
	color: string;
	backgroundColor: string;
}

const StatusBubble = ({ label, iconName, color, backgroundColor }: StatusBubbleProps) => {
	return (
		<div className={"status-bubble-main-wrap add-border"}>
			<div className={"status-bubble-inner"} style={{ color, backgroundColor }}>
				<div className="status-bubble-icon">
					<Icon iconName={iconName} />
				</div>
				<div className="status-bubble-text">{label}</div>
			</div>
		</div>
	);
};

export default StatusBubble;
