import { createContext, PropsWithChildren, useContext, useRef, useState } from "react";

import ModalDialog, { ModalDialogButtonInfo } from "../Components/ModalDialog/ModalDialog";
import { CommonModalButtons } from "../Utils/ModalUtils";

export interface ModalContext {
    showConfirmModal: (title: string, message: string, callback: () => void) => void;
    showErrorModal: (title: string, message: string, callback?: () => void) => void;
}

const MODAL_CONTEXT_DEFAULT: ModalContext = {
    showConfirmModal(title, message, callback) {},
    showErrorModal(title, message, callback) {},
};
export const ModalContext = createContext<ModalContext>(MODAL_CONTEXT_DEFAULT);
export const useModalContext = () => useContext(ModalContext);

export const ModalContextProvider = (props: PropsWithChildren<{}>) => {
    const [confirmTitle, setConfirmTitle] = useState("");
    const [confirmText, setConfirmText] = useState("");
    const [confirmButtons, setConfirmButtons] = useState<ModalDialogButtonInfo[]>();
    const confirmCallback = useRef<() => void>();
    const [confirmShown, showConfirm] = useState(false);

    const [errorTitle, setErrorTitle] = useState("");
    const [errorText, setErrorText] = useState("");
    const [errorButtons, setErrorButtons] = useState<ModalDialogButtonInfo[]>();
    const errorCallback = useRef<() => void>();
    const [errorShown, showError] = useState(false);

    const okButtons = CommonModalButtons.ok({ onAccept: () => confirmCallback.current?.() });
    const confirmCancelButtons = CommonModalButtons.confirmCancel({
        onAccept: () => confirmCallback.current?.(),
        onCancel: () => showConfirm(false),
    });

    const showConfirmModal = (title: string, message: string, callback: () => void) => {
        setConfirmTitle(title);
        setConfirmText(message);
        setConfirmButtons(confirmCancelButtons);
        confirmCallback.current = () => {
            callback();
            showConfirm(false);
        };
        showConfirm(true);
    };

    const showErrorModal = (title: string, message: string, callback?: () => void) => {
        setErrorTitle(title);
        setErrorText(message);
        setErrorButtons(okButtons);
        confirmCallback.current = () => {
            callback?.();
            showError(false);
        };
        showError(true);
    };

    const modalContextValue: ModalContext = {
        showConfirmModal,
        showErrorModal,
    };

    return (
        <ModalContext.Provider value={modalContextValue}>
            {props.children}
            <ModalDialog
                enableModal={confirmShown}
                modalMessage={confirmText}
                modalTitle={confirmTitle}
                onAccept={confirmCallback.current}
                onAbort={() => showConfirm(false)}
                modalButtons={confirmButtons}
            />
            <ModalDialog
                enableModal={errorShown}
                modalMessage={errorText}
                modalTitle={errorTitle}
                onAccept={errorCallback.current}
                onAbort={() => showError(false)}
                modalButtons={errorButtons}
            />
        </ModalContext.Provider>
    );
};
