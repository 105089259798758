import { IContextualMenuProps } from "@fluentui/react";
import { DefaultButton } from "@fluentui/react/lib/Button";
import React, { useEffect, useState } from "react";
import "./LabelButton1.scss";

export interface ILabelButtonProps {
    whiteOutlined?: boolean;
    redOutlined?: boolean;
    blueOutlined?: boolean;
    orangeSolid?: boolean;
    greenSolid?: boolean;
    darkSolid?: boolean;
    blueSolid?: boolean;
    darkBackground?: boolean;
    fitContent?: boolean;

    text: string;
    icon?: string;
    disabled?: boolean;
    onClick?: () => void;
    href?: string;
    target?: string;

    split?: boolean;
    menuProps?: IContextualMenuProps;
    onMenuClick?: () => void;

    fullWidth?: boolean;
}

export const LabelButton = (props: ILabelButtonProps) => {
    const [className, setClassName] = useState<string>("");

    useEffect(() => {
        let relevantWrapClass = "";

        if (!props.disabled) {
            if (props.whiteOutlined) {
                relevantWrapClass = "label-button-white-outlined";
            }
            if (props.redOutlined) {
                relevantWrapClass = "label-button-red-outlined";
            }
            if (props.blueOutlined) {
                relevantWrapClass = "label-button-blue-outlined";
            }
            if (props.darkSolid) {
                relevantWrapClass = "label-button-dark-solid";
            }
            if (props.orangeSolid) {
                relevantWrapClass = "label-button-orange-solid";
            }
            if (props.greenSolid) {
                relevantWrapClass = "label-button-green-solid";
            }
            if (props.blueSolid) {
                relevantWrapClass = "label-button-blue-solid";
            }

            if (relevantWrapClass === "") {
                relevantWrapClass = "label-button-white-outlined";
            }

            if (props.darkBackground) {
                relevantWrapClass += " force-white-text";
            }

            setClassName(relevantWrapClass);
        }
    }, [props.disabled]);

    return (
        <div className={className}>
            <DefaultButton
                iconProps={props.icon ? { iconName: props.icon } : {}}
                text={props.text}
                onClick={props.onClick ? props.onClick : () => {}}
                disabled={props.disabled}
                href={props.href}
                target={props.target}
                split={props.split}
                menuProps={props.menuProps}
                onMenuClick={props.onMenuClick ? props.onMenuClick : () => {}}
                styles={
                    props.fullWidth
                        ? { root: { width: "100%" }, splitButtonContainer: props.split ? { width: "100%" } : {} }
                        : undefined
                }
            />
        </div>
    );
};

export default LabelButton;
