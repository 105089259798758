import { create } from "zustand";
import BusinessObject, { ToolBarFile } from "../Models/BusinessObject";

export interface SelectionState {
	selection: ToolBarFile[];
	setSelection: (items: ToolBarFile[]) => void;
}

const useSelectionStore = create<SelectionState>((set) => ({
	selection: [],
	setSelection: (items: ToolBarFile[]) => set((state) => ({ selection: items })),
}));

export default useSelectionStore;
