import StatusBubble, { StatusBubbleProps } from "./StatusBubble";

export const LakeJobStatusItems: StatusBubbleProps[] = [
	{ label: "Pending", iconName: "Edit", color: "white", backgroundColor: "#D83B01" },
	{ label: "Progress", iconName: "Timer", color: "white", backgroundColor: "#EF6900" },
	{ label: "Completed", iconName: "Checkmark", color: "white", backgroundColor: "#107C10" },
	{ label: "Unterminated", iconName: "Error", color: "#605e5c", backgroundColor: "white" },
];

const LakeJobStatusBubble = ({ status }: { status: number }) => {
	return <StatusBubble {...LakeJobStatusItems[status]} />;
};

export default LakeJobStatusBubble;
