import { ErrorLog } from "@/Pages/Dev/ErrorLog/ErrorLog";
import { createEmptyRequest, AjaxService, IAPIRequest, IAPIResponse } from "../Internal/AjaxService";
import { EmailLog } from "@/Pages/Dev/EmailLog/EmailLog";

const LogsController = {
    getErrorLogs: async () => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = "/logs/error";
        let response: IAPIResponse = await AjaxService.call(request);
        if (response.error) throw new Error(response.error);
        return response.payload as ErrorLog[];
    },
    getEmailLogs: async () => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = "/logs/email";
        let response: IAPIResponse = await AjaxService.call(request);
        if (response.error) throw new Error(response.error);
        return response.payload as EmailLog[];
    },
};

export default LogsController;
