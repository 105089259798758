import { ModalDialogButtonInfo } from "@Eni/docware-fe-master";

export interface ModalButtonProps {
	onCancel?: () => void;
	onDelete?: () => void;
	onAccept?: () => void;
	confirmDisabled?: boolean;
}

export const CommonModalButtons = {
	noYes: (props: Pick<ModalButtonProps, "onCancel" | "onAccept">) =>
		[
			{
				label: "No",
				onClick: props.onCancel,
			},
			{
				label: "Yes",
				onClick: props.onAccept,
			},
		] as ModalDialogButtonInfo[],

	confirmCancel: (props: Pick<ModalButtonProps, "onCancel" | "onAccept" | "confirmDisabled">) =>
		[
			{
				label: "Cancel",
				onClick: props.onCancel,
			},
			{
				label: "Confirm",
				disabled: props.confirmDisabled ?? false,
				onClick: props.onAccept,
			},
		] as ModalDialogButtonInfo[],

	ok: (props: Pick<ModalButtonProps, "onAccept">) =>
		[
			{
				label: "Ok",
				onClick: props.onAccept,
			},
		] as ModalDialogButtonInfo[],

	deleteCancelSave: (props: Pick<ModalButtonProps, "onCancel" | "onAccept" | "onDelete" | "confirmDisabled">) =>
		[
			{
				label: "Delete",
				onClick: props.onDelete,
			},
			{
				label: "Cancel",
				onClick: props.onCancel,
			},
			{
				label: "Save",
				disabled: props.confirmDisabled ?? false,
				onClick: props.onAccept,
			},
		] as ModalDialogButtonInfo[],
};
