import { UserPermissions } from "@/Models/UserPermissions";
import BusinessObject, { BusinessObjectType } from "../../Models/BusinessObject";
import { AjaxService, IAPIRequest, IAPIResponse, createEmptyRequest } from "../Internal/AjaxService";

const BusinessObjectsController = {
    getDefault: async () => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = "/businessobjects";
        let response: IAPIResponse = await AjaxService.call(request);
        if (response.error != null) throw new Error(response.error);
        const payload = response.payload as BusinessObject[];
        const businessObjects = payload.map((x) => ({
            ...x,
            createdDate: new Date(x.createdDate),
            updatedDate: !!x.updatedDate ? new Date(x.updatedDate) : null,
            publishDate: !!x.publishDate ? new Date(x.publishDate) : null,
            countries: new Map<string, string>(Object.entries(x.countries)),
        }));
        return businessObjects as BusinessObject[];
    },
    getDefaultPublished: async (callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = "/businessobjects/default-published";
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    getSearch: async (type: BusinessObjectType, search: string, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = `/businessobjects/search?search=${search}&type=${type}`;
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    getPublished: async (callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = "/businessobjects/published";
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    getBusinessObject: async (id: string, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = `/businessobjects/${id}`;
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    createBusinessObject: async (id: string, type: BusinessObjectType, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = `/businessobjects?id=${id}&type=${type}`;
        request.method = "POST";
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    updateBusinessObject: async (id: string, currentDescription: string, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = `/businessobjects/${id}`;
        request.method = "PATCH";
        request.payload = currentDescription;
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    saveAndPublish: async (id: string, currentDescription: string, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = `/businessobjects/${id}/save-and-publish`;
        request.method = "POST";
        request.payload = currentDescription;
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    saveAndRequestPublish: async (id: string, currentDescription: string, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = `/businessobjects/${id}/request-publish`;
        request.method = "POST";
        request.payload = currentDescription;
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    reject: async (id: string, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = `/businessobjects/${id}/reject`;
        request.method = "POST";
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    approve: async (id: string, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = `/businessobjects/${id}/approve`;
        request.method = "POST";
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    getPermissions: async (id: string) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = `/businessobjects/${id}/permissions`;
        request.method = "GET";
        let response: IAPIResponse = await AjaxService.call(request);
        if (response.error !== null) throw new Error(response.error);
        return response.payload as UserPermissions;
    },
    deletePublishedDescription: async (id: string, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = `/businessobjects/${id}/delete-published`;
        request.method = "DELETE";
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    shareBusinessObjectAsync: async (id: string, recipientIds: string[], callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = `/businessobjects/${id}/share`;
        request.method = "POST";
        request.payload = recipientIds;
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
};

export default BusinessObjectsController;
