import { useState } from "react";

import { Label, Stack, Toggle, Text } from "@fluentui/react";

import ModalDialog from "../../../Components/ModalDialog/ModalDialog";
import User, { UserRole } from "../../../Models/User";
import ApiService from "../../../Services/ApiService";
import { IAPIResponse } from "../../../Services/Internal/AjaxService";
import { CommonModalButtons } from "../../../Utils/ModalUtils";
import WindowToast from "../../../Utils/WindowToast";
import { useModalContext } from "../../../Context/ModalContext";
import { useLoaderContext } from "../../../Context/LoaderContext";
import { queryClient } from "@/ProviderWrapper";

interface EditUserModalProps {
    user: User;
    show: boolean;
    setShow: (show: boolean) => void;
}

const EditUserModal = ({ user, show, setShow }: EditUserModalProps) => {
    const modal = useModalContext();
    const loader = useLoaderContext();

    const [roles, setRoles] = useState<Set<UserRole>>(() => new Set(user.roles));

    const closeModal = () => {
        setShow(false);
        setRoles(new Set());
    };

    const updateRoles = () => {
        loader?.show();
        ApiService.UsersController.updateRole(user.id, roles)
            .then(async (response: IAPIResponse) => {
                if (response.error !== null) throw response.error;

                await queryClient.invalidateQueries({ queryKey: ["users"] });
                closeModal();
            })
            .catch(WindowToast.error)
            .finally(loader?.hide);
    };

    const deleteUser = () => {
        loader?.show();
        ApiService.UsersController.deleteUser(user.id)
            .then(async (response: IAPIResponse) => {
                if (response.error !== null) throw response.error;
                closeModal();
                await queryClient.invalidateQueries({ queryKey: ["users"] });
            })
            .catch(WindowToast.error)
            .finally(loader?.hide);
    };

    const onToggle = (checked: boolean | undefined, role: UserRole) => {
        if (checked === undefined) return;
        setRoles((prev) => {
            if (checked) {
                prev.add(role);
                if (role === UserRole.Writer || role === UserRole.Approver) {
                    prev.add(UserRole.Reader);
                }
            } else prev.delete(role);
            return new Set(prev);
        });
    };

    return (
        <ModalDialog
            modalTitle={"Edit User"}
            modalMessage={`${user.firstName} ${user.lastName}`}
            modalInnerComponent={
                <div>
                    <Stack horizontal className="modal-header-row">
                        <Toggle
                            label={"Admin"}
                            checked={roles.has(UserRole.Admin)}
                            onChange={(event, checked) => onToggle(checked, UserRole.Admin)}
                        />
                        <Toggle
                            label={"Reader"}
                            checked={roles.has(UserRole.Reader)}
                            onChange={(event, checked) => onToggle(checked, UserRole.Reader)}
                            disabled={roles.has(UserRole.Writer) || roles.has(UserRole.Approver)}
                        />
                        <Toggle
                            label={"Writer"}
                            checked={roles.has(UserRole.Writer)}
                            onChange={(event, checked) => onToggle(checked, UserRole.Writer)}
                        />
                        <Toggle
                            label={"Approver"}
                            checked={roles.has(UserRole.Approver)}
                            onChange={(event, checked) => onToggle(checked, UserRole.Approver)}
                        />
                    </Stack>

                    <Label>Countries</Label>
                    <Text>
                        {Array.from(user.countries)
                            .map(([key, name]) => name)
                            .join(", ")}
                    </Text>
                </div>
            }
            modalButtons={CommonModalButtons.deleteCancelSave({
                onDelete: () => {
                    modal?.showConfirmModal("Delete user", "Are you sure you want to delete this user?", deleteUser);
                },
                onCancel: closeModal,
                onAccept: updateRoles,
            })}
            onAbort={closeModal}
            enableModal={show}
        />
    );
};
export default EditUserModal;
