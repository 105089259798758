import { useEffect } from "react";

import GenericList from "../../../Components/GenericList/GenericList";
import ErrorPage from "../../../Components/ErrorPage/ErrorPage";
import BusinessObject, { ToolBarFile } from "../../../Models/BusinessObject";
import ApiService from "../../../Services/ApiService";
import useSelectionStore from "../../../Stores/SelectionStore";
import useToolbarStore, { ToolbarType } from "../../../Stores/ToolbarStore";
import { WikiListTableColumns } from "./WikiListTableColumns";
import { SelectionMode } from "@fluentui/react";
import { useQuery } from "@tanstack/react-query";
import Loader from "@/Components/Loader/Loader";

const businessObjectQuery = async () => {
    const res = await ApiService.BusinessObjectsController.getPublished();
    if (res.error !== null) throw new Error("Error retrieving Business Objects");

    let businessObjects = (res.payload as BusinessObject[]).map((x) => ({
        ...x,
        createdDate: new Date(x.createdDate),
        updatedDate: !!x.updatedDate ? new Date(x.updatedDate) : null,
        publishDate: !!x.publishDate ? new Date(x.publishDate) : null,
        countries: new Map<string, string>(Object.entries(x.countries)),
    }));

    return businessObjects;
};

export function WikiList() {
    const {
        data: businessObjects,
        isLoading,
        error,
    } = useQuery({ queryKey: ["wikiListBusinessObjects"], queryFn: businessObjectQuery });

    const setToolbarType = useToolbarStore((state) => state.setType);
    const setSelection = useSelectionStore((state) => state.setSelection);

    useEffect(() => {
        document.title = "Wiki BO - WikiList";
        setToolbarType(ToolbarType.WikiList);
    }, []);

    const onSelectionChanged = (businessObjects: BusinessObject[]) => {
        const files: ToolBarFile[] = businessObjects.map((bo) => ({
            id: bo.id,
            code: bo.code,
            status: bo.status,
            type: bo.type,
            locked: false, //CHECK
        }));

        setSelection(files);
    };

    if (isLoading) return <Loader display={true} />;
    if (error) {
        const message = (error as Error).message;
        return <ErrorPage title={"An error occured inside WikiList tab"} message={message} page="Wiki List" />;
    }

    return (
        <div>
            <GenericList
                allowFilter={true}
                searchText={"Search BOs"}
                items={businessObjects!}
                columns={WikiListTableColumns}
                onSelectionChanged={onSelectionChanged}
                selectionMode={SelectionMode.multiple}
            />
        </div>
    );
}
