import { AjaxService, IAPIRequest, IAPIResponse, createEmptyRequest } from "../Internal/AjaxService";

const ExcelController = {
    saveSelectedBusinessObjectsToExcel: async (ids: string[], callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = "/excel/business-objects";
        request.method = "POST";
        request.payload = ids;
        request.skipResponseJsonParse = true;
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
};

export default ExcelController;
