import { EditLock } from "@/Models/EditLock";
import { AjaxService, createEmptyRequest, IAPIRequest, IAPIResponse } from "../Internal/AjaxService";

const EditLockController = {
    lock: async (id: string) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = `/editlock/${id}/lock`;
        request.method = "POST";
        let response: IAPIResponse = await AjaxService.call(request);
        return response;
    },
    unlock: async (id: string) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = `/editlock/${id}/unlock`;
        request.method = "POST";
        let response: IAPIResponse = await AjaxService.call(request);
        return response;
    },
    getLock: async (id: string) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = `/editlock/${id}`;
        let response: IAPIResponse = await AjaxService.call(request);
        return response;
    },
    getLocks: async () => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = `/editlock`;
        let response: IAPIResponse = await AjaxService.call(request);
        if (response.error != null) throw new Error(response.error);
        return response.payload as EditLock[];
    },
};

export default EditLockController;
