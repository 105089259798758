const dispatchEvent = (text: string, type: WindowToastTypes) => {
    window.document.dispatchEvent(
        new CustomEvent("api-toast-result", {
            detail: {
                text,
                type,
            },
        })
    );
};

const WindowToast = {
    error: (text: string) => dispatchEvent(text, "error"),
    warning: (text: string) => dispatchEvent(text, "warning"),
    info: (text: string) => dispatchEvent(text, "info"),
    success: (text: string) => dispatchEvent(text, "success"),
    dispatchEvent: (text: string, type: WindowToastTypes) => dispatchEvent(text, type),
};

export default WindowToast;

export type WindowToastTypes = "error" | "warning" | "info" | "success";
