import "./Toolbar.scss";

import { useNavigate } from "react-router-dom";
import _ from "underscore";

import { ICommandBarItemProps, IPersonaProps } from "@fluentui/react";

import AppRoutes from "../../Constants/AppRoutes";
import { useLoaderContext } from "../../Context/LoaderContext";
import { BusinessObjectStatus } from "../../Models/BusinessObject";
import User, { UserRole } from "../../Models/User";
import ApiService from "../../Services/ApiService";
import useSelectionStore from "../../Stores/SelectionStore";
import useUserStore from "../../Stores/UserStore";
import WindowToast from "../../Utils/WindowToast";
import StyledCommandBar from "./StyledCommandBar";
import { useModalContext } from "../../Context/ModalContext";
import ModalDialog from "../ModalDialog/ModalDialog";
import { useState } from "react";
import { CommonModalButtons } from "../../Utils/ModalUtils";
import { PeoplePicker } from "../PeoplePicker/PeoplePicker";
import { bonifyEmailField } from "../../Pages/Admin/Users/NewUserModal";
import Loader from "../Loader/Loader";
import { useQuery } from "@tanstack/react-query";

export interface HomeToolbarProps {}

export const HomeToolbar = (props: HomeToolbarProps) => {
    const navigate = useNavigate();

    const homeSelection = useSelectionStore((state) => state.selection);
    // const setHomeSelection = useSelectionStore((state) => state.setSelection);

    const loader = useLoaderContext();
    const modal = useModalContext();

    const loggedUser = useUserStore((state) => state.user);

    const [showModal, setShowModal] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState<IPersonaProps[]>([]);
    const [wikiBOUsers, setWikiBOUsers] = useState<IPersonaProps[]>([]);
    const [usersLoaded, setUsersLoaded] = useState(false);

    const selectedFile = _.first(homeSelection);
    const {
        data: permissions,
        error: permissionsError,
        isFetching: isPermissionsLoading,
    } = useQuery({
        queryKey: ["permissions", selectedFile?.id],
        enabled: selectedFile !== undefined,
        queryFn: () => ApiService.BusinessObjectsController.getPermissions(selectedFile!.id),
    });
    
    const getUsers = () => {
        setUsersLoaded(false);
        ApiService.UsersController.getUsers()
            .then((res) => {
                if (res.error !== null) {
                    throw new Error("Error retrieving Users");
                }
                const users = res.payload as User[];
                const personas: IPersonaProps[] = users.map((u) => ({
                    key: u.id,
                    text: `${u.firstName} ${u.lastName}`,
                    secondaryText: bonifyEmailField(u.email),
                }));
                setWikiBOUsers(personas);
            })
            .catch(WindowToast.error)
            .finally(() => setUsersLoaded(true));
    };

    const onAccept = () => {
        const businessObject = _.first(homeSelection)!;
        loader?.show();
        ApiService.BusinessObjectsController.shareBusinessObjectAsync(
            businessObject.id,
            selectedUsers.filter((x) => x.key !== undefined && x.key !== null).map((x) => x.key!.toString())
        )
            .then((res) => {
                if (res.error) throw res.error;
                WindowToast.success("Business Object shared successfully");
                closeModal();
            })
            .catch(WindowToast.error)
            .finally(loader?.hide);
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedUsers([]);
    };

    const modalButtons = CommonModalButtons.confirmCancel({
        onAccept,
        onCancel: closeModal,
        confirmDisabled: selectedUsers.length === 0,
    });

    if (loggedUser === undefined) {
        return null;
    }

    let items: ICommandBarItemProps[] = [];
    const farItems: ICommandBarItemProps[] = [];

    if (homeSelection.length === 0) {
        items = [
            {
                key: "admin-area",
                text: "Admin Area",
                iconProps: { iconName: "Admin" },
                disabled: !loggedUser.roles.has(UserRole.Admin),
                onClick: () => {
                    navigate(AppRoutes.ADMIN_USERS);
                },
            },
        ];
    }

    if (homeSelection.length === 1) {
        if (permissionsError) {
            WindowToast.error("Error retrieving permissions on Business Object");
        }

        if (isPermissionsLoading || permissionsError) {
            items = [];
        } else {
            const file = _.first(homeSelection)!;
            items = [
                {
                    key: "view",
                    text: "View",
                    iconProps: { iconName: "Page" },
                    disabled: !permissions!.canAccessRead,
                    onClick: () => {
                        navigate(AppRoutes.DETAIL_READ.replace(":id", file.id));
                    },
                },
                {
                    key: "edit",
                    text: "Edit",
                    iconProps: { iconName: "Edit" },
                    disabled: !permissions!.canAccessEdit,
                    onClick: async () => {
                        if (file.status === BusinessObjectStatus.NotPresent) {
                            loader?.show();
                            await ApiService.BusinessObjectsController.createBusinessObject(file.code, file.type)
                                .then((res) => {
                                    if (res.error !== null) {
                                        WindowToast.error(res.error);
                                        return;
                                    }
                                    file.id = res.payload.id;
                                })
                                .finally(loader?.hide);
                        }
                        if (file.locked) {
                            modal?.showErrorModal(
                                "Edit is temporalily unavailable",
                                "Another user is editing the document. Document will be accessible in view only until this document will be saved"
                            );
                        } else {
                            navigate(AppRoutes.DETAIL_EDIT.replace(":id", file.id));
                        }
                    },
                },
                {
                    key: "share",
                    text: "Share",
                    iconProps: { iconName: "Send" },
                    disabled: !permissions!.canShare,
                    onClick: () => {
                        getUsers();
                        setShowModal(true);
                    },
                },
            ];
        }
    }

    return (
        <>
            <StyledCommandBar commandBarProps={{ items, farItems }} isLoading={isPermissionsLoading} />

            <ModalDialog
                enableModal={showModal}
                modalTitle={"SHARE"}
                modalMessage={"Select users"}
                modalInnerComponent={
                    !usersLoaded ? (
                        <Loader display={true} />
                    ) : (
                        <PeoplePicker
                            selectedUsers={selectedUsers}
                            onFilterChanged={function (
                                filterText: string,
                                currentPersonas: IPersonaProps[] | undefined,
                                limitResults?: number | undefined
                            ): IPersonaProps[] | Promise<IPersonaProps[]> {
                                return wikiBOUsers
                                    .filter((x) => x.text?.toLowerCase().startsWith(filterText.toLowerCase()))
                                    .filter((x) => !currentPersonas?.map((c) => c.key).includes(x.key));
                            }}
                            onPeopleChanged={function (updatedPeople: IPersonaProps[] | undefined): void {
                                setSelectedUsers(updatedPeople ?? []);
                            }}
                        />
                    )
                }
                onAccept={onAccept}
                onAbort={closeModal}
                modalButtons={modalButtons}
            />
        </>
    );
};
