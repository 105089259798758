import "./Toolbar.scss";

import { useNavigate, useParams, useRouteLoaderData } from "react-router-dom";
import _ from "underscore";

import { ICommandBarItemProps } from "@fluentui/react";

import AppRoutes from "../../Constants/AppRoutes";
import useUserStore from "../../Stores/UserStore";
import WindowToast from "../../Utils/WindowToast";
import StyledCommandBar from "./StyledCommandBar";
import { useLoaderContext } from "../../Context/LoaderContext";
import { APISettings, AjaxService } from "../../Services/Internal/AjaxService";
import BusinessObject from "../../Models/BusinessObject";

export interface DetailEditToolbarProps {}

export const DetailEditToolbar = (props: DetailEditToolbarProps) => {
    const navigate = useNavigate();
    const routeLoaderData = useRouteLoaderData("detail") as { businessObject: BusinessObject } | undefined;

    const user = useUserStore((state) => state.user);

    const loader = useLoaderContext();

    if (routeLoaderData === undefined || user === undefined) {
        return null;
    }
    const { businessObject } = routeLoaderData;

    const items: ICommandBarItemProps[] = [
        {
            key: "home",
            text: "Home",
            iconProps: { iconName: "Home" },
            onClick: () => {
                navigate(AppRoutes.HOME);
            },
        },
        {
            key: "export",
            text: "Export",
            iconProps: { iconName: "ExcelDocument" },
            onClick: () => {
                loader?.show();
                AjaxService.downloadFileFromUrl(
                    `${APISettings.baseUrl}/excel/business-objects?ids=${businessObject.id}`,
                    `business_objects.xlsx`
                );
                loader?.hide();
            },
        },
    ];
    const farItems: ICommandBarItemProps[] = [];

    return <StyledCommandBar commandBarProps={{ items, farItems }} />;
};
