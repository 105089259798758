import { BusinessObjectStatus } from "../../Models/BusinessObject";
import { UserRole } from "../../Models/User";

export const isViewEnabled = (status: BusinessObjectStatus, userRoles: Set<UserRole>) => {
    if (status === BusinessObjectStatus.NotPresent) {
        return false;
    }

    let isEnabled = false;
    if (userRoles.has(UserRole.Admin)) {
        isEnabled = true;
    }
    if (userRoles.has(UserRole.Approver)) {
        if (status === BusinessObjectStatus.Pending || status === BusinessObjectStatus.Published) {
            isEnabled = true;
        }
    }
    if (userRoles.has(UserRole.Writer)) {
        isEnabled = true;
    }
    if (userRoles.has(UserRole.Reader)) {
        if (status === BusinessObjectStatus.Published) {
            isEnabled = true;
        }
    }
    return isEnabled;
};

export const isEditEnabled = (status: BusinessObjectStatus, userRoles: Set<UserRole>) => {
    let isEnabled = false;

    if (userRoles.has(UserRole.Admin)) {
        isEnabled = true;
    }
    if (userRoles.has(UserRole.Approver)) {
        if (status === BusinessObjectStatus.Pending) {
            isEnabled = true;
        }
    }
    if (userRoles.has(UserRole.Writer)) {
        isEnabled = true;
    }
    if (userRoles.has(UserRole.Reader)) {
        // return false;
    }
    return isEnabled;
};

export const isShareEnabled = (status: BusinessObjectStatus, userRoles: Set<UserRole>) => {
    let isEnabled = false;

    if (status === BusinessObjectStatus.Published) {
        isEnabled = true;
    }

    return isEnabled;
};