import "./Toolbar.scss";

import { useNavigate } from "react-router-dom";
import _ from "underscore";

import { ICommandBarItemProps } from "@fluentui/react";

import AppRoutes from "../../Constants/AppRoutes";
import StyledCommandBar from "./StyledCommandBar";

export interface DevToolbarProps {}

export const DevToolbar = (props: DevToolbarProps) => {
    const navigate = useNavigate();

    const items: ICommandBarItemProps[] = [
        {
            key: "home",
            text: "Home",
            iconProps: { iconName: "Home" },
            onClick: () => {
                navigate(AppRoutes.HOME);
            },
        },
    ];
    const farItems: ICommandBarItemProps[] = [];

    return <StyledCommandBar commandBarProps={{ items, farItems }} />;

};
