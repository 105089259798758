import * as React from "react";
import { DetailsList, DetailsListLayoutMode, Selection, SelectionMode, IColumn } from "@fluentui/react/lib/DetailsList";
import { MarqueeSelection } from "@fluentui/react/lib/MarqueeSelection";
import { useEffect, useState } from "react";
import LabelButton from "../LabelButton/LabelButton";
import { Dropdown } from "@fluentui/react";

export type ModifiableIColumn = Omit<IColumn, "key" | "isRowHeader" | "isSorted" | "isSortedDescending">;

function copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}

interface CustomListProps<T> {
    columns: ModifiableIColumn[];
    items: T[];
    isModalSelection?: boolean;
    selectionMode?: SelectionMode;
    onSelectionChanged?: (items: T[]) => void;
    onItemInvoked?: (item: T[]) => void;
    paginated?: boolean;
}

const CustomList = <T extends object>(props: CustomListProps<T>) => {
    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        const newItems = copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
        setColumns(newColumns);
        setItems(newItems);
    };

    const [columns, setColumns] = useState<IColumn[]>(props.columns.map((c, i) => getIColumn(c, i)));
    const [items, setItems] = useState<T[]>(props.items);
    const [selection] = useState<Selection>(
        new Selection({
            onSelectionChanged: () => {
                if (props.onSelectionChanged) {
                    props.onSelectionChanged(selection.getSelection() as T[]);
                }
            },
        })
    );

    useEffect(() => {
        setItems(props.items);
    }, [props.items]);

    useEffect(() => {
        setColumns(props.columns.map((c, i) => getIColumn(c, i)));
    }, [props.columns]);

    const onItemInvoked = (item: any): void => {
        if (props.onItemInvoked) {
            props.onItemInvoked(item);
        }
    };

    function getIColumn(column: ModifiableIColumn, index: number): IColumn {
        return {
            key: "column" + index.toString(),
            fieldName: column.fieldName,
            // minWidth: 0,
            // maxWidth: 0,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            data: column.data,
            isPadded: false,
            // name: column.name,
            // onRender: column.onRender,
            onColumnClick: onColumnClick,
            ...column,
        };
    }

    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const maxPages: number = Math.ceil(items.length / itemsPerPage);

    return (
        <div>
            {props.isModalSelection ? (
                <MarqueeSelection selection={selection}>
                    <DetailsList
                        items={props.paginated ? items.slice(page * itemsPerPage, (page + 1) * itemsPerPage) : items}
                        columns={columns}
                        selectionMode={props.selectionMode ? props.selectionMode : SelectionMode.none}
                        setKey="multiple"
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        selection={selection}
                        selectionPreservedOnEmptyClick={true}
                        onItemInvoked={onItemInvoked}
                        enterModalSelectionOnTouch={true}
                        ariaLabelForSelectionColumn="Toggle selection"
                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        checkButtonAriaLabel="select row"
                    />
                </MarqueeSelection>
            ) : (
                <DetailsList
                    items={props.paginated ? items.slice(page * itemsPerPage, (page + 1) * itemsPerPage) : items}
                    columns={columns}
                    selectionMode={props.selectionMode ? props.selectionMode : SelectionMode.none}
                    setKey="none"
                    layoutMode={DetailsListLayoutMode.justified}
                    selection={selection}
                    isHeaderVisible={true}
                    onItemInvoked={onItemInvoked}
                    selectionPreservedOnEmptyClick={true}
                />
            )}

            {!!props.paginated && (
                <div className="paginator-wrapper">
                    <LabelButton
                        text="Back"
                        icon="ChromeBack"
                        whiteOutlined
                        disabled={page === 0}
                        onClick={() => setPage((prev) => prev - 1)}
                    />
                    <div className="paginator-center-items">
                        <span style={{ marginRight: "1em" }}>Items per page: </span>
                        <Dropdown
                            options={[
                                { key: 10, text: "10" },
                                { key: 20, text: "20" },
                                { key: 50, text: "50" },
                                { key: 100, text: "100" },
                            ]}
                            selectedKey={itemsPerPage}
                            onChange={(event, option) => {
                                if (option?.key) {
                                    setPage(0);
                                    setItemsPerPage(Number(option.key));
                                }
                            }}
                        />
                        <span style={{ marginLeft: "1em" }}>
                            {maxPages === 0 ? 0 : 1 + page * itemsPerPage} -{" "}
                            {maxPages === 0 ? 0 : Math.min((page + 1) * itemsPerPage, items.length)} of {items.length}
                        </span>
                    </div>
                    <LabelButton
                        text="Next"
                        icon="ChromeBackMirrored"
                        orangeSolid
                        disabled={page + 1 >= maxPages}
                        onClick={() => setPage((prev) => prev + 1)}
                    />
                </div>
            )}
        </div>
    );
};

export default CustomList;

export const ListSizes = {
    micro: {
        minWidth: 16,
        maxWidth: 16,
    },
    small: {
        minWidth: 20,
        maxWidth: 40,
    },
    little: {
        minWidth: 40,
        maxWidth: 60,
    },
    moderate: {
        minWidth: 60,
        maxWidth: 100,
    },
    medium: {
        minWidth: 100,
        maxWidth: 120,
    },
    bigger: {
        minWidth: 160,
        maxWidth: 190,
    },
    large: {
        minWidth: 210,
        maxWidth: 350,
    },
    wide: {
        minWidth: 450,
        maxWidth: 600,
    },
};
