import { create } from "zustand";
import { LakeTask, LakeTaskStatusType } from "../Models/LakeTask";

export interface lakeJobsToolbarState {
    selection: LakeTask[];
    setSelection: (items: LakeTask[]) => void;
}

const useLakeJobsToolbarStore = create<lakeJobsToolbarState>((set) => ({
    selection: [],
    setSelection: (items: LakeTask[]) => set((state) => ({ ...state, selection: items })),
}));

export default useLakeJobsToolbarStore;
