import { Dropdown } from "@fluentui/react";
import LabelButton from "../LabelButton/LabelButton";

import "./Paginator.scss";

export interface PaginatorProps {
    totalRecords: number;
    page: number;
    perPage: number;
    onChangeItemsPerPage: (e: { page: number; itemsPerPage: number }) => void;
}

const Paginator = ({ totalRecords, onChangeItemsPerPage, page, perPage }: PaginatorProps): JSX.Element => {
    const maxPages: number = Math.ceil(totalRecords / perPage);

    return (
        <div className="paginator-wrapper">
            <LabelButton
                text="Back"
                icon="ChromeBack"
                whiteOutlined
                disabled={page === 0}
                onClick={() => onChangeItemsPerPage({ page: page - 1, itemsPerPage: perPage })}
            />
            <div className="paginator-center-items">
                <span style={{ marginRight: "1em" }}>Items per page: </span>
                <Dropdown
                    options={[
                        { key: 10, text: "10" },
                        { key: 20, text: "20" },
                        { key: 50, text: "50" },
                        { key: 100, text: "100" },
                    ]}
                    selectedKey={perPage}
                    onChange={(event, option) => {
                        if (option?.key) {
                            onChangeItemsPerPage({ page: 0, itemsPerPage: Number(option.key) });
                        }
                    }}
                />
                <span style={{ marginLeft: "1em" }}>
                    {maxPages === 0 ? 0 : 1 + page * perPage} -{" "}
                    {maxPages === 0 ? 0 : Math.min((page + 1) * perPage, totalRecords)} of {totalRecords}
                </span>
            </div>
            <LabelButton
                text="Next"
                icon="ChromeBackMirrored"
                orangeSolid
                disabled={page + 1 >= maxPages}
                onClick={() => onChangeItemsPerPage({ page: page + 1, itemsPerPage: perPage })}
            />
        </div>
    );
};

export default Paginator;
