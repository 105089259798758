import ErrorPage from "../../../Components/ErrorPage/ErrorPage";
import ApiService from "../../../Services/ApiService";
import { LakeTask } from "../../../Models/LakeTask";
import { Dropdown, IDropdownOption, SelectionMode, Spinner, Stack } from "@fluentui/react";
import { useEffect, useState } from "react";
import useToolbarStore, { ToolbarType } from "../../../Stores/ToolbarStore";
import LabelButton from "../../../Components/LabelButton/LabelButton";
import ModalDialog from "../../../Components/ModalDialog/ModalDialog";
import { useLoaderContext } from "../../../Context/LoaderContext";
import WindowToast from "../../../Utils/WindowToast";
import { CommonModalButtons } from "../../../Utils/ModalUtils";
import useLakeJobsToolbarStore from "../../../Stores/LakeJobsToolbarStore";
import { ProfessionalArea } from "../../../Models/ProfessionalArea";
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "@/ProviderWrapper";
import Loader from "@/Components/Loader/Loader";
import CustomList from "@/Components/GenericList/CustomList";
import { getLakeJobsDetailsColumns } from "./LakeJobsDetailsColumns";

export interface LakeJobsTableActions {
    info: (item: LakeTask) => void;
    duplicate: (item: LakeTask) => void;
    moveToTrash: (item: LakeTask) => void;
    restart: (item: LakeTask) => void;
}

export const LakeJobsQueryKeys = {
    professionalAreas: "professionalAreas",
    countries: "countries",
    lakeTasks: "lakeTasks",
};

export function LakeJobs() {
    const professionaAreaResult = useQuery({
        queryKey: [LakeJobsQueryKeys.professionalAreas],
        queryFn: () => ApiService.DocwareOutboundController.getProfessionalAreas(),
    });

    const countriesResult = useQuery({
        queryKey: [LakeJobsQueryKeys.countries],
        queryFn: () => ApiService.InboundController.getCountries(),
    });

    const lakeTasksResult = useQuery({
        queryKey: ["lakeTasks"],
        queryFn: () => ApiService.DocwareLakeController.getTasks(),
        refetchInterval: 30_000,
    });

    const setToolbarType = useToolbarStore((state) => state.setType);
    const loader = useLoaderContext();
    const setToolbarSelection = useLakeJobsToolbarStore((state) => state.setSelection);

    const [showInfoModal, setShowInfoModal] = useState(false);
    const [infoTask, setInfoTask] = useState<LakeTask | undefined>();

    const [showNewJobModal, setShowNewJobModal] = useState(false);

    const [selectedCountryKeys, setSelectedCountryKeys] = useState<string[]>([]);
    const [selectedDocumentSubTypeKeys, setSelectedDocumentSubTypeKeys] = useState<string[]>([]);
    const [selectedProfessionalAreaKeys, setSelectedProfessionalAreaKeys] = useState<string[]>([]);

    useEffect(() => {
        document.title = "Wiki BO - Lake Jobs";
        setToolbarType(ToolbarType.LakeJobs);

        return () => {
            setToolbarSelection([]);
        };
    }, []);

    if (professionaAreaResult.isLoading) return <Loader display={true} />;
    if (countriesResult.isLoading) return <Loader display={true} />;
    if (lakeTasksResult.isLoading) return <Loader display={true} />;

    if (professionaAreaResult.isError) return ErrorMessage(professionaAreaResult.error as Error);
    if (countriesResult.isError) return ErrorMessage(countriesResult.error as Error);
    if (lakeTasksResult.isError) return ErrorMessage(lakeTasksResult.error as Error);

    const onSelectionChanged = (items: LakeTask[]) => {
        setToolbarSelection(items);
    };

    const newJob = () => {
        setShowNewJobModal(true);
    };

    const actions: LakeJobsTableActions = {
        duplicate(task) {
            if (task.filters === null) {
                WindowToast.error("Task query is null. Cannot perfor operation");
            } else {
                setSelectedCountryKeys(task.filters.countries);
                setSelectedProfessionalAreaKeys(task.filters.professional_areas);
                setSelectedDocumentSubTypeKeys(task.filters.document_subtypes);

                setShowNewJobModal(true);
            }
        },
        info(task) {
            setInfoTask(task);
            setShowInfoModal(true);
        },
        moveToTrash(task) {
            loader?.show();
            ApiService.DocwareLakeController.terminateJob(task.taskId)
                .then((res) => {
                    if (res.error !== null) throw res.error;
                    WindowToast.success("Job terminated successfully");
                })
                .catch(WindowToast.error)
                .finally(loader?.hide);
        },
        restart(task) {
            loader?.show();
            ApiService.DocwareLakeController.restartJob(task.taskId)
                .then((res) => {
                    if (res.error !== null) throw res.error;
                    WindowToast.success("Job restarted successfully");
                })
                .catch(WindowToast.error)
                .finally(loader?.hide);
        },
    };

    const sendRequest = () => {
        loader?.show();
        ApiService.DocwareLakeController.newJob({
            countries: selectedCountryKeys,
            professionalAreas: selectedProfessionalAreaKeys,
            documentSubtypes: selectedDocumentSubTypeKeys,
        })
            .then(async (res) => {
                if (res.error !== null) throw new Error("Error creating job");
                WindowToast.success("New job created successfully");
                // const task = res.payload as LakeTask;
                await queryClient.invalidateQueries({ queryKey: ["lakeTasks"] });
                setToolbarSelection([]);
            })
            .catch(WindowToast.error)
            .finally(loader?.hide);
    };

    const parseOption = (prevKeys: string[], option: IDropdownOption<any>) => {
        return option.selected ? [...prevKeys, option.key as string] : prevKeys.filter((key) => key !== option.key);
    };

    const countryOptions = Array.from(countriesResult.data)
        .map(([key, text]) => ({ key, text }))
        .sort((a, b) => a.text.localeCompare(b.text));

    const professionalAreaOptions = professionaAreaResult.data
        .map((x) => ({
            key: x.id,
            text: x.name,
        }))
        .sort((a, b) => a.text.localeCompare(b.text));

    const documentSubTypeOptions = professionaAreaResult.data
        .filter((x) => selectedProfessionalAreaKeys.includes(x.id))
        .flatMap((x) => x.documentTypes)
        .flatMap((x) => x.subTypes)
        .map((x) => ({ key: x.id, text: x.name }))
        .sort((a, b) => a.text.localeCompare(b.text));

    const closeModal = () => {
        setShowNewJobModal(false);
        setSelectedCountryKeys([]);
        setSelectedProfessionalAreaKeys([]);
        setSelectedDocumentSubTypeKeys([]);
    };

    const professionalAreasToSubtypes = (
        totalProfessionalAreas: ProfessionalArea[],
        selectedProfessionalAreas: string[]
    ) => {
        let subTypesWithDuplicates = totalProfessionalAreas
            .filter((x) => selectedProfessionalAreas.includes(x.id))
            .flatMap((x) => x.documentTypes)
            .flatMap((x) => x.subTypes);

        let uniqueSubtypes = subTypesWithDuplicates.filter(
            (obj, index) => index === subTypesWithDuplicates.findIndex((x) => obj.id === x.id)
        );
        return uniqueSubtypes;
    };

    return (
        <div>
            <Stack horizontal>
                <LabelButton text={"New Job"} icon="Add" orangeSolid onClick={newJob} />
                {lakeTasksResult.isFetching && <Spinner label="Loading jobs..." />}
            </Stack>

            <CustomList
                columns={getLakeJobsDetailsColumns(actions)}
                items={lakeTasksResult.data}
                selectionMode={SelectionMode.single}
                onSelectionChanged={onSelectionChanged}
                paginated
            />

            <ModalDialog
                enableModal={showInfoModal}
                modalTitle="Job parameteres"
                modalInnerComponent={
                    infoTask !== undefined && (
                        <Stack>
                            <span>
                                Countries:{" "}
                                {Array.from(countriesResult.data!.entries())
                                    .filter(([key, name]) => infoTask!.filters.countries.includes(key))
                                    .map(([key, name]) => name)
                                    .join(", ")}
                            </span>
                            <span>
                                Professional Areas:{" "}
                                {professionaAreaResult.data
                                    .filter((x) => infoTask!.filters.professional_areas.includes(x.id))
                                    .map((x) => x.name)
                                    .join(", ")}
                            </span>
                            <span>
                                Document Subtypes:{" "}
                                {professionalAreasToSubtypes(
                                    professionaAreaResult.data,
                                    infoTask!.filters.professional_areas
                                )
                                    .filter((x) => infoTask!.filters.document_subtypes.includes(x.id))
                                    .map((x) => x.name)
                                    .join(", ")}
                            </span>
                        </Stack>
                    )
                }
                modalButtons={CommonModalButtons.ok({
                    onAccept: () => setShowInfoModal(false),
                })}
                onAbort={() => setShowInfoModal(false)}
            />

            <ModalDialog
                enableModal={showNewJobModal}
                modalTitle="New Job"
                modalInnerComponent={
                    <Stack>
                        <Dropdown
                            label="Country"
                            options={countryOptions}
                            multiSelect
                            onChange={(event, option) => {
                                if (option !== undefined) {
                                    setSelectedCountryKeys((prev) => parseOption(prev, option));
                                }
                            }}
                            selectedKeys={selectedCountryKeys}
                        />
                        <Dropdown
                            label="Professional Area"
                            options={professionalAreaOptions}
                            multiSelect
                            onChange={(event, option) => {
                                if (option !== undefined) {
                                    setSelectedProfessionalAreaKeys((prev) => parseOption(prev, option));
                                }
                            }}
                            selectedKeys={selectedProfessionalAreaKeys}
                        />
                        <Dropdown
                            label="Document Subtype"
                            options={documentSubTypeOptions}
                            multiSelect
                            onChange={(event, option) => {
                                if (option !== undefined) {
                                    setSelectedDocumentSubTypeKeys((prev) => parseOption(prev, option));
                                }
                            }}
                            selectedKeys={selectedDocumentSubTypeKeys}
                            disabled={selectedProfessionalAreaKeys.length === 0}
                        />
                    </Stack>
                }
                onAbort={closeModal}
                modalButtons={[
                    {
                        label: "Cancel",
                        onClick: () => {
                            closeModal();
                        },
                    },
                    {
                        label: "Send Request",
                        onClick: () => {
                            sendRequest();
                            closeModal();
                        },
                        disabled:
                            selectedCountryKeys.length === 0 ||
                            selectedProfessionalAreaKeys.length === 0 ||
                            selectedDocumentSubTypeKeys.length === 0,
                    },
                ]}
            />
        </div>
    );
}

const ErrorMessage = (error: Error) => (
    <ErrorPage title={"An error occured inside LakeJobs tab"} message={error.message} page="LakeJobs" />
);
