import { LabelButton } from "@Eni/docware-fe-master";
import { TooltipHost } from "@fluentui/react";

import User, { UserRole } from "../../../Models/User";
import Icons from "../../../Utils/FabricIconsOutlet";

export const userTableColumns = (onEdit: (user: User) => void) => [
    {
        name: "Admin",
        dataType: "string",
        fieldName: "",
        size: "little",
        onRender: (user: User) =>
            user.roles.has(UserRole.Admin) ? (
                <span style={{ color: "#84d195" }}>{Icons.getIcon("SkypeCircleCheck")}</span>
            ) : (
                <span style={{ color: "#d18484" }}>{Icons.getIcon("StatusErrorFull")}</span>
            ),
    },
    {
        name: "Reader",
        dataType: "string",
        fieldName: "",
        size: "little",
        onRender: (user: User) =>
            user.roles.has(UserRole.Reader) ? (
                <span style={{ color: "#84d195" }}>{Icons.getIcon("SkypeCircleCheck")}</span>
            ) : (
                <span style={{ color: "#d18484" }}>{Icons.getIcon("StatusErrorFull")}</span>
            ),
    },
    {
        name: "Writer",
        dataType: "string",
        fieldName: "",
        size: "little",
        onRender: (user: User) =>
            user.roles.has(UserRole.Writer) ? (
                <span style={{ color: "#84d195" }}>{Icons.getIcon("SkypeCircleCheck")}</span>
            ) : (
                <span style={{ color: "#d18484" }}>{Icons.getIcon("StatusErrorFull")}</span>
            ),
    },
    {
        name: "Approver",
        dataType: "string",
        fieldName: "",
        size: "little",
        onRender: (user: User) =>
            user.roles.has(UserRole.Approver) ? (
                <span style={{ color: "#84d195" }}>{Icons.getIcon("SkypeCircleCheck")}</span>
            ) : (
                <span style={{ color: "#d18484" }}>{Icons.getIcon("StatusErrorFull")}</span>
            ),
    },
    {
        name: "ID",
        dataType: "string",
        fieldName: "id",
        size: "medium",
    },
    {
        name: "Name",
        dataType: "string",
        fieldName: "name",
        size: "large",
        onRender: (user: User) => `${user.firstName} ${user.lastName}`,
    },
    {
        name: "Email",
        dataType: "string",
        fieldName: "email",
        size: "large",
    },
    {
        name: "Countries (#)",
        dataType: "string",
        fieldName: "countries",
        size: "medium",
        onRender: (user: User) => (
            <TooltipHost content={[...user.countries.values()].join(", ")}>
                {user.countries.has("W") ? "WORLD" : user.countries.size}
            </TooltipHost>
        ),
    },
    {
        fieldName: "editButton",
        size: "medium",
        onRender: (user: User) => <LabelButton text="Edit" icon="Edit" onClick={() => onEdit(user)} />,
    },
];
