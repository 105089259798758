import { BaseUser } from "./User";

export interface LakeQuery {
	countries: string[];
	document_subtypes: string[];
	limit: number;
	offset: number;
	professional_areas: string[];
}

export interface LakeTask {
	completedDocuments: number;
	documentsToProcess: number;
	failedDocuments: number;
	lastUpdatedAt: Date;
	filters: LakeQuery;
	startDate: Date;
	status: LakeTaskStatusType;
	taskId: string;
	totalDocuments: number;
	unsupportedDocuments: number;
	author: BaseUser;
}

export enum LakeTaskStatusType
{
    Pending,
    Progress,
    Completed,
	Unterminated
}
