import * as React from "react";

import { registerIcons, unregisterIcons } from "@fluentui/react";
import {
    ArrowRepeatAllRegular,
    ArrowSyncRegular,
    BrainCircuit20Regular,
    BrainCircuitRegular,
    CalendarRegular,
    CopyRegular,
    DeleteRegular,
    DraftsRegular,
    InfoRegular,
    MoreCircleRegular,
    PenRegular,
    SaveEditRegular,
    SaveRegular,
    TargetArrowRegular,
    TextDescriptionRegular,
    TextWordCountRegular,
    TimerRegular,
} from "@fluentui/react-icons";

import { LoaderContextProvider } from "./Context/LoaderContext";
import { ModalContextProvider } from "./Context/ModalContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

// https://developer.microsoft.com/en-us/fluentui#/styles/web/icons
unregisterIcons(["Calendar", "Delete", "Copy", "Info", "Save", "Timer"]);

registerIcons({
    icons: {
        Calendar: <CalendarRegular />,
        ArrowSync: <ArrowSyncRegular />,
        TextWordCount: <TextWordCountRegular />,
        TargetArrow: <TargetArrowRegular />,
        BrainCircuit: <BrainCircuit20Regular />,
        Drafts: <DraftsRegular />,
        Pen: <PenRegular />,
        Delete: <DeleteRegular />,
        Copy: <CopyRegular />,
        Info: <InfoRegular />,
        Save: <SaveRegular />,
        SaveEdit: <SaveEditRegular />,
        TextDescription: <TextDescriptionRegular />,
        Timer: <TimerRegular />,
        MoreCircle: <MoreCircleRegular />,
        ArrowRepeatAll: <ArrowRepeatAllRegular />,
    },
});

export const queryClient = new QueryClient();

export const ProviderWrapper = (props: any) => {
    return (
        <React.StrictMode>
            <QueryClientProvider client={queryClient}>
                <LoaderContextProvider>
                    <ModalContextProvider>{props.children}</ModalContextProvider>
                </LoaderContextProvider>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </React.StrictMode>
    );
};
