import "./Toolbar.scss";

import { useNavigate, useRouteLoaderData } from "react-router-dom";
import _ from "underscore";

import { ICommandBarItemProps } from "@fluentui/react";

import AppRoutes from "../../Constants/AppRoutes";
import BusinessObject from "../../Models/BusinessObject";
import { UserPermissions } from "../../Models/UserPermissions";
import useUserStore from "../../Stores/UserStore";
import { isEditEnabled } from "./HomeButtons";
import StyledCommandBar from "./StyledCommandBar";
import useToolbarStore, { ToolbarType } from "../../Stores/ToolbarStore";

export interface DetailReadToolbarProps {}

export const DetailReadToolbar = (props: DetailReadToolbarProps) => {
    const routeLoaderData = useRouteLoaderData("detail") as
        | { businessObject: BusinessObject; permissions: Readonly<UserPermissions> }
        | undefined;
    const navigate = useNavigate();

    const user = useUserStore((state) => state.user);
    const setToolbarType = useToolbarStore((state) => state.setType);

    if (routeLoaderData === undefined || user === undefined) {
        return null;
    }
    const { businessObject, permissions } = routeLoaderData;

    const items: ICommandBarItemProps[] = [
        {
            key: "home",
            text: "Home",
            iconProps: { iconName: "Home" },
            onClick: () => {
                setToolbarType(ToolbarType.Home);
                navigate(AppRoutes.HOME);
            },
        },
        {
            key: "edit",
            text: "Edit",
            iconProps: { iconName: "Edit" },
            disabled: !isEditEnabled(businessObject.status, user.roles),
            onClick: () => {
                navigate(AppRoutes.DETAIL_EDIT.replace(":id", businessObject.id));
            },
        },
    ];
    const farItems: ICommandBarItemProps[] = [];

    return <StyledCommandBar commandBarProps={{ items, farItems }} />;

};
