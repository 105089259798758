import "./Loader.scss";

import { CSSProperties } from "react";

export interface LoaderProps {
    display: boolean;
    text?: string;
    // overlay?: boolean; //TODO can manage non global spinner
}

const Loader = (props: LoaderProps) => {
    const wrapperStyle: CSSProperties = {
        position: "fixed",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        top: "48px",
        left: "48px",
        width: "100%",
        height: "100%",
        zIndex: "10000001",
        background: "rgba(255,255,255,0.7)",
    };

    const spinnerStyle: CSSProperties = {
        border: "0.5em solid",
        borderColor: "#3b84aa22 #3b84aa22 #3b84aa #3b84aa22",
        width: "min(30vw, 10em)",
        height: "min(30vw, 10em)",
        borderRadius: "50%",

        animation: "0.5s linear infinite spin",
    };

    const textStyle: CSSProperties = {
        position: "absolute",
        marginTop: "15em",
        padding: "0 1em",
        background: "white",
    };

    if (props.display)
        return (
            <div style={wrapperStyle} id="spinner-wrap">
                <div style={spinnerStyle}></div>
                <span style={textStyle}>{props.text ?? "Loading..."}</span>
            </div>
        );

    return null;
};

export default Loader;
