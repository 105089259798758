import { ListSizes, ModifiableIColumn } from "@/Components/GenericList/CustomList";
import { EmailLog } from "./EmailLog";

export const emailLogTableColumns = [
    {
        name: "Id",
        dataType: "string",
        fieldName: "id",
        size: "medium",
    },
    {
        name: "EmailType",
        dataType: "string",
        fieldName: "emailType",
        size: "bigger",
    },
    {
        name: "Tos",
        dataType: "string",
        fieldName: "tos",
        size: "bigger",
        onRender: (log: EmailLog) => log.tos.join(", "),
    },
    // {
    //     name: "CCs",
    //     dataType: "string",
    //     fieldName: "CCs",
    //     size: "medium",
    //     onRender: (log: EmailLog) => log.CCs.join(", "),
    // },
    // {
    //     name: "BCCs",
    //     dataType: "string",
    //     fieldName: "BCCs",
    //     size: "medium",
    //     onRender: (log: EmailLog) => log.BCCs.join(", "),
    // },
    {
        name: "Date",
        dataType: "string",
        fieldName: "date",
        size: "bigger",
    },
    {
        name: "Message",
        dataType: "string",
        fieldName: "message",
        size: "large",
        onRender: (log: EmailLog) => <div dangerouslySetInnerHTML={{ __html: log.message }}></div>,
    },
];

export const emailLogCustomColumns: ModifiableIColumn[] = [
    {
        name: "Id",
        data: "string",
        fieldName: "id",
        minWidth: ListSizes.medium.minWidth,
        maxWidth: ListSizes.medium.maxWidth,
    },
    {
        name: "EmailType",
        data: "string",
        fieldName: "emailType",
        minWidth: ListSizes.bigger.minWidth,
        maxWidth: ListSizes.bigger.maxWidth,
    },
    {
        name: "Tos",
        data: "string",
        fieldName: "tos",
        minWidth: ListSizes.bigger.minWidth,
        maxWidth: ListSizes.bigger.maxWidth,
        onRender: (log: EmailLog) => log.tos.join(", "),
    },
    // {
    //     name: "CCs",
    //     data: "string",
    //     fieldName: "CCs",
    //     size: "medium",
    //     onRender: (log: EmailLog) => log.CCs.join(", "),
    // },
    // {
    //     name: "BCCs",
    //     data: "string",
    //     fieldName: "BCCs",
    //     size: "medium",
    //     onRender: (log: EmailLog) => log.BCCs.join(", "),
    // },
    {
        name: "Date",
        data: "string",
        fieldName: "date",
        minWidth: ListSizes.bigger.minWidth,
        maxWidth: ListSizes.bigger.maxWidth,
    },
    {
        name: "Message",
        data: "string",
        fieldName: "message",
        minWidth: ListSizes.large.minWidth,
        maxWidth: ListSizes.large.maxWidth,
        onRender: (log: EmailLog) => <div dangerouslySetInnerHTML={{ __html: log.message }}></div>,
    },
];
