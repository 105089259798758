import ActivitiesController from "./Controllers/ActivitiesController";
import BusinessObjectsController from "./Controllers/BusinessObjectsController";
import DocwareLakeController from "./Controllers/DocwareLakeController";
import DocwareOutboundController from "./Controllers/DocwareOutboundController";
import EditLockController from "./Controllers/EditLockController";
import LogsController from "./Controllers/LogsController";
import ExcelController from "./Controllers/ExcelController";
import InboundController from "./Controllers/InboundController";
import IntegrationsController from "./Controllers/IntegrationsController";
import UsersController from "./Controllers/UsersController";

const ApiService = {
    ActivitiesController,
    UsersController,
    BusinessObjectsController,
    DocwareLakeController,
    EditLockController,
    ExcelController,
    IntegrationsController,
    DocwareOutboundController,
    InboundController,
    LogsController,
};

export default ApiService;
