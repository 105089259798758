import { useRef, useState } from "react";
import _ from "underscore";

import { IPersonaProps, Stack } from "@fluentui/react";

import { PeoplePicker } from "../../../Components/PeoplePicker/PeoplePicker";
import User, { UserRole } from "../../../Models/User";
import { CommonModalButtons } from "../../../Utils/ModalUtils";
import ApiService from "../../../Services/ApiService";
import { IAPIResponse } from "../../../Services/Internal/AjaxService";
import WindowToast from "../../../Utils/WindowToast";
import { useNavigate } from "react-router-dom";
import { useLoaderContext } from "../../../Context/LoaderContext";
import ModalDialog from "../../../Components/ModalDialog/ModalDialog";
import { Banner } from "@Eni/docware-fe-master";
import { queryClient } from "@/ProviderWrapper";

export const bonifyEmailField = (email: string) => {
    if (email && email !== "") {
        return email;
    }
    return "No email available";
};

export const graphUserToPersonas = (users: GraphUser[]) => {
    return users.map((u) => {
        return {
            key: u.onPremisesSamAccountName,
            text: u.displayName,
            secondaryText: bonifyEmailField(u.mail),
        };
    }) as IPersonaProps[];
};

interface GraphUser {
    displayName: string;
    givenName: string;
    surname: string;
    onPremisesSamAccountName: string;
    mail: string;
    userPrincipalName: string;
}

export const getGraphUsers = async (search: string) => {
    let users: IPersonaProps[] = [];
    await ApiService.UsersController.getGraphUsers(search, (response: IAPIResponse) => {
        if (response.error === null) {
            let payload = response.payload as GraphUser[];
            users = graphUserToPersonas(payload.filter((x) => x.mail.endsWith("eni.com")));
        } else {
            WindowToast.error("There was an error loading the users");
        }
    });
    return users;
};

interface NewUserModalProps {
    show: boolean;
    setShow: (show: boolean) => void;
    existingUsers: User[];
}

const NewUserModal = ({ show, setShow, existingUsers }: NewUserModalProps) => {
    const navigate = useNavigate();

    const loader = useLoaderContext();

    const [persona, setPersona] = useState<IPersonaProps>();
    const [personaError, setPersonaError] = useState<string>("");

    const insertUser = () => {
        setPersonaError("");
        if (!persona || !persona.key) {
            setPersonaError("Invalid user");
            return;
        }

        loader?.show();
        ApiService.UsersController.insertUserFromId(persona.key.toString())
            .then(async (response: IAPIResponse) => {
                if (response.error !== null) throw response.error;
                closeModal();

                await queryClient.invalidateQueries({ queryKey: ["users"] });
            })
            .catch((err) => WindowToast.error(err))
            .finally(loader?.hide);
    };

    const filterPeopleList = async (filterText: string, currentPersonas: IPersonaProps[]) => {
        let graphUsers: IPersonaProps[] = [];
        if (filterText.length > 1) {
            graphUsers = await getGraphUsers(filterText);
        }
        //filter users already present in db
        if (!!existingUsers && existingUsers.length > 0) {
            graphUsers = graphUsers.filter((x) => !existingUsers.some((y) => y.id === x.id));
        }
        return graphUsers;
    };

    const closeModal = () => {
        setShow(false);
        setPersona(undefined);
        setPersonaError("");
    };

    return (
        <ModalDialog
            modalTitle={"New User"}
            modalMessage={"Choose a user to import"}
            modalInnerComponent={
                <Stack>
                    <Banner message={personaError} enabled={personaError !== ""} />
                    <PeoplePicker
                        label="User"
                        selectedUsers={persona ? [persona] : []}
                        onFilterChanged={function (
                            filterText: string,
                            currentPersonas: IPersonaProps[] | undefined,
                            limitResults?: number
                        ): IPersonaProps[] | Promise<IPersonaProps[]> {
                            return filterPeopleList(filterText, currentPersonas ?? []);
                        }}
                        onPeopleChanged={function (updatedPeople: IPersonaProps[] | undefined): void {
                            let user = _.first(updatedPeople ?? []) as IPersonaProps;
                            setPersona(user);
                        }}
                        itemLimit={1}
                    />
                </Stack>
            }
            modalButtons={CommonModalButtons.confirmCancel({
                onCancel: closeModal,
                onAccept: insertUser,
            })}
            onAbort={closeModal}
            enableModal={show}
        />
    );
};
export default NewUserModal;
