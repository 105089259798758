import { create } from "zustand";
import User from "../Models/User";

export interface UserState {
	user?: User;
	setUser: (user?: User) => void;
}

const useUserStore = create<UserState>((set) => ({
	user: undefined,
	setUser: (user?: User) => set((state) => ({ user })),
}));

export default useUserStore;
