import "./Users.scss";

import { useEffect, useState } from "react";
import { useLoaderData, useNavigate, useRouteError } from "react-router-dom";

import { LabelButton } from "@Eni/docware-fe-master";
import { Stack } from "@fluentui/react";

import ErrorPage from "../../../Components/ErrorPage/ErrorPage";
import GenericList from "../../../Components/GenericList/GenericList";
import { useLoaderContext } from "../../../Context/LoaderContext";
import User, { UserRole } from "../../../Models/User";
import ApiService from "../../../Services/ApiService";
import useUserStore from "../../../Stores/UserStore";
import WindowToast from "../../../Utils/WindowToast";
import EditUserModal from "./EditUserModal";
import NewUserModal from "./NewUserModal";
import { userTableColumns } from "./UserTableColumns";
import useToolbarStore, { ToolbarType } from "../../../Stores/ToolbarStore";
import { IAPIResponse } from "../../../Services/Internal/AjaxService";
import Paginator from "../../../Components/Paginator/Paginator";
import { useQuery } from "@tanstack/react-query";
import Loader from "@/Components/Loader/Loader";
import { queryClient } from "@/ProviderWrapper";

const DEFAULT_USER = {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    creationDate: new Date(),
    roles: new Set<UserRole>(),
    countries: new Map<string, string>(),
};

const usersQuery = async () => {
    let res = await ApiService.UsersController.getUsers();
    if (res.error !== null) throw new Error("Error retrieving Users");

    let users = res.payload as User[];
    users = users.map((x) => ({
        ...x,
        roles: new Set(x.roles),
        countries: new Map<string, string>(Object.entries(x.countries)),
    }));

    return users;
};

export function Users() {
    const { data: users, error, isLoading } = useQuery({ queryKey: ["users"], queryFn: usersQuery });

    const navigate = useNavigate();
    const loader = useLoaderContext();

    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(20);

    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [showNewModal, setShowNewModal] = useState<boolean>(false);

    const [editUser, setEditUser] = useState<User>(DEFAULT_USER);

    const setToolbarType = useToolbarStore((state) => state.setType);

    useEffect(() => {
        document.title = "Wiki BO - Users";
        setToolbarType(ToolbarType.Admin);
    }, []);

    const syncPermissions = () => {
        loader?.show();
        ApiService.UsersController.syncPermissions()
            .then(async (response: IAPIResponse) => {
                if (response.error !== null) throw response.error;
                WindowToast.success("Permissions Synced!");
                await queryClient.invalidateQueries({ queryKey: ["users"] });
            })
            .catch(WindowToast.error)
            .finally(loader?.hide);
    };

    if (isLoading) return <Loader display={true} />;
    if (!!error) {
        const message = (error as Error).message;
        return <ErrorPage title={"An error occured inside Users tab"} message={message} page="Users" />;
    }

    return (
        <div>
            <GenericList
                allowFilter={true}
                searchText={"Search users"}
                extraHeaderItems={[
                    <Stack horizontal key={"element_types"} style={{ gap: "1em", padding: "1em" }}>
                        <LabelButton text={"New"} icon="Add" onClick={() => setShowNewModal(true)} />
                        <LabelButton orangeSolid text="Sync Countries Now" icon="Sync" onClick={syncPermissions} />
                    </Stack>,
                ]}
                items={users!.slice(page * itemsPerPage, (page + 1) * itemsPerPage)}
                columns={userTableColumns((u: User) => {
                    setEditUser(u);
                    setShowEditModal(true);
                })}
            />

            <Paginator
                totalRecords={users!.length}
                page={page}
                perPage={itemsPerPage}
                onChangeItemsPerPage={(e: { page: number; itemsPerPage: number }): void => {
                    setPage(e.page);
                    setItemsPerPage(e.itemsPerPage);
                }}
            />

            <NewUserModal
                show={showNewModal}
                setShow={(show: boolean) => setShowNewModal(show)}
                existingUsers={users!}
                key={"NewUserModal-" + new Date().toString()}
            />

            <EditUserModal
                user={editUser}
                show={showEditModal}
                setShow={(show: boolean) => {
                    setShowEditModal(show);
                    if (!show) {
                        setEditUser(DEFAULT_USER);
                    }
                }}
                key={"EditUserModal-" + new Date().toString()}
            />
        </div>
    );
}
