import StatusBubble, { StatusBubbleProps } from "./StatusBubble";

export const BOstatusItems: StatusBubbleProps[] = [
	{ label: "", iconName: "PageEdit", color: "", backgroundColor: "" },
	{ label: "Draft", iconName: "Edit", color: "#605E5C", backgroundColor: "#EDEBE9" },
	{ label: "Pending", iconName: "Timer", color: "#605e5c", backgroundColor: "white" },
	{ label: "Published", iconName: "Pen", color: "white", backgroundColor: "#005a59" },
];

const BOStatusBubble = ({ status }: { status: number }) => {
	return <StatusBubble {...BOstatusItems[status]} />;
};

export default BOStatusBubble;
