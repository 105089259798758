import {Icon} from '@fluentui/react/lib/Icon';
import ColoredIcons from './base64Icons';

const IconsList: Array<string> = [
  'Installation',
  'FabricFolder',
  'FileSymlink',
  'Touch',
  'PageHeaderEdit',
  'Page',
  'Calendar',
  'Info',
  'Attach',
  'Add',
  'Archive',
  'Code',
  'DRM',
  'UserGauge',
  'Source',
  'FavoriteStar',
  'RecycleBin',
  'MultiSelectMirrored',
  'ErrorBadge',
  'Help',
  'SyncToPC',
  'OpenSource',
  'Settings',
  'Ringer',
  'ReminderTime',
  'Edit',
  'IncreaseIndentArrow',
  'DecreaseIndentArrow',
  'FavoriteStarFill',
  'CheckMark',
  'Blocked12',
  'Error',
  'CloudUpload',
  'OpenFile',
  'SkypeCheck',
  'Unknown',
  'ChromeBack',
  'ChromeBackMirrored',
  'StatusErrorFull',
  'Clock',
  'PenWorkspace',
  'ShieldSolid',
  'SkypeCircleCheck',
  'Globe',
  'ShieldAlert',
  'FolderOpen',
  'Library',
  'Delete',
  'PageEdit',
  'MoreVertical',
  'FollowUser',
  'DocumentApproval',
  'Admin',
  'UserFollowed',
  'RemoveFromShoppingList',
  'RadioBtnOff',
  'RadioBtnOn',
  'ChevronDown',
  'ChevronUp',
  'Search',
  'Copy',
  'VersionControlPush',
  'Certificate',
  'Save',
  'SaveAs',
  'DocumentApproval',
  'Cancel',
  'ChevronRight',
  'ChevronLeft',
  'ZoomIn',
  'ZoomOut',
  'Warning',
  'SingleBookmark',
  'CircleAddition',
  'Calendar',
];

const getColoredImageBase64 = (name: string) => {
  return ColoredIcons[name];
};

const getColoredIcon = (name: string, size?: string) => {
  let icon: string = ColoredIcons[name];
  if (icon != null && icon !== undefined) {
    return <img style={{height: size ? size : '1em', width: size ? size : '1em'}} alt="" src={icon} />;
  } else {
    return <div style={{userSelect: 'none', width: '2em'}}>{'<?>'}</div>;
  }
};

const getColoredImage = (name: string, height?: string) => {
  let icon: string = ColoredIcons[name];
  if (icon != null && icon !== undefined) {
    return <img style={{height: height ? height : '1em'}} alt="" src={icon} />;
  } else {
    return <div style={{userSelect: 'none', width: '2em'}}>{'<?>'}</div>;
  }
};

const getIcon = (name: string, fontSize?: string) => {
  let icon = null;
  for (let i = 0; i < IconsList.length; i++) {
    if (IconsList[i].toLocaleLowerCase() === name.toLocaleLowerCase()) {
      icon = IconsList[i];
      break;
    }
  }

  if (icon != null) {
    return <Icon style={{fontSize: fontSize ? fontSize : '1em'}} iconName={icon} />;
  } else {
    return <div style={{userSelect: 'none', width: '2em'}}>{'<?>'}</div>;
  }
};

const showAll = () => {
  let all: any = [];
  IconsList.forEach((item, i) => {
    all.push(
      <div key={i} style={{display: 'flex', alignItems: 'center'}}>
        <span style={{minWidth: '10em', marginRight: '1em'}}>{item}</span>
        <span>{getIcon(item)}</span>
      </div>
    );
  });
  return all;
};

const getSizedColoredImage = (name: string, width: string, height: string) => {
  let icon: string = ColoredIcons[name];
  if (icon != null && icon !== undefined) {
    return <img style={{height: height, width: width}} alt="" src={icon} />;
  } else {
    return <div style={{userSelect: 'none', width: '2em'}}>{'<?>'}</div>;
  }
};

const Icons = {
  showAll,
  getColoredImage,
  getColoredIcon,
  getIcon,
  getColoredImageBase64,
  getSizedColoredImage,
};

export default Icons;
