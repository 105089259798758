import "./EmailLog.scss";

import _ from "underscore";

import { emailLogCustomColumns } from "./EmailLogTableColumns";
import ApiService from "../../../Services/ApiService";
import { useQuery } from "@tanstack/react-query";
import Loader from "@/Components/Loader/Loader";
import ErrorPage from "@/Components/ErrorPage/ErrorPage";
import CustomList from "@/Components/GenericList/CustomList";

export interface EmailLog {
    id: string;
    emailType: string;
    tos: string[];
    CCs: string[];
    BCCs: string[];
    date: string;
    message: string;
}

export function EmaiLog() {
    const {
        data: emailLogs,
        isLoading,
        error,
    } = useQuery({
        queryKey: ["emailLogs"],
        queryFn: () => ApiService.LogsController.getEmailLogs(),
    });

    if (isLoading) return <Loader display={true} />;
    if (error) {
        let message = (error as Error | undefined)?.message;
        if (message)
            return <ErrorPage title={"An error occured inside ErrorLog tab"} message={message} page="ErrorLog" />;
    }

    return (
        <div>
            <CustomList items={emailLogs!} columns={emailLogCustomColumns} paginated />
            {/* <GenericList
                allowFilter={true}
                searchText={"Search logs"}
                items={emailLogs!}
                columns={emailLogTableColumns}
            /> */}
        </div>
    );
}
