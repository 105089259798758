import User from "./User";

export enum BusinessObjectStatus {
    NotPresent,
    Draft,
    Pending,
    Published,
}

export enum BusinessObjectType {
    PLAY,
    LAYER,
    PROSPECT,
}

export const BusinessObjectTypeName: Record<BusinessObjectType, string> = {
    [BusinessObjectType.PLAY]: "PLAY",
    [BusinessObjectType.LAYER]: "LAYER",
    [BusinessObjectType.PROSPECT]: "PROSPECT",
};

export const BusinessObjectTypeOptions = (Object.values(BusinessObjectType) as Array<BusinessObjectType>).map((x) => ({
    key: x,
    text: BusinessObjectTypeName[x],
}));

// [
//     { name: 'draft', iconName: 'PageEdit' },
//     { name: 'review', iconName: 'UserSync' },
//     { name: 'reviewedDraft', iconName: 'Completed' },
//     { name: 'pending', iconName: 'ReminderTime' },
//     { name: 'signed', iconName: 'PenWorkspace' },
//   ];

export interface DocwareLakeDescription {
    modelId: string;
    modelName: string;
    description: string;
}

export default interface BusinessObject {
    id: string;
    code: string;
    name: string;
    type: BusinessObjectType;
    status: BusinessObjectStatus;
    createdDate: Date;
    updatedDate: Date | null;
    updatedUser: User | null;
    aiDescriptions: DocwareLakeDescription[];
    currentDescription: string;
    publishedDescription: string;
    publishDate: Date | null;
    publisher: User | null;
    countries: Map<string, string>;
}

export interface ToolBarFile {
    id: string;
    code: string;
    type: BusinessObjectType;
    status: BusinessObjectStatus;
    locked: boolean;
    // canView: boolean;
    // canEdit: boolean;
    // canDelete: boolean;
}

export const placeholderBusinessObject: BusinessObject = {
    id: "",
    code: "",
    name: "",
    type: BusinessObjectType.PLAY,
    status: BusinessObjectStatus.Draft,
    createdDate: new Date(),
    updatedDate: null,
    updatedUser: null,
    aiDescriptions: [],
    currentDescription: "",
    publishedDescription: "",
    publishDate: null,
    publisher: null,
    countries: new Map<string, string>(),
};
