import { ListSizes, ModifiableIColumn } from "@/Components/GenericList/CustomList";
import ServiceStatusBubble from "../../../Components/StatusBubble/ServiceStatusBubble";

export const WikiServicesTableColumns: ModifiableIColumn[] = [
    {
        name: "Service",
        data: "string",
        fieldName: "name",
        minWidth: ListSizes.large.minWidth,
        maxWidth: ListSizes.large.maxWidth,
    },
    {
        name: "Status",
        data: "string",
        fieldName: "status",
        minWidth: ListSizes.large.minWidth,
        maxWidth: ListSizes.large.maxWidth,
        onRender: (item: any) => <ServiceStatusBubble status={item.status} />,
    },
];
