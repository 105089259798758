import { IActivity } from "@/Components/ActivityColumn/ActivityColumn";
import { AjaxService, IAPIRequest, IAPIResponse, createEmptyRequest } from "../Internal/AjaxService";

const ActivitiesController = {
    getUserActivities: async () => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = "/activities/user";
        let response: IAPIResponse = await AjaxService.call(request);
        if (response.error != null) throw new Error(response.error);
        return response.payload as IActivity[];
    },

    getActivities: async (businessObjectId: string) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = `/activities/reference/${businessObjectId}`;
        let response: IAPIResponse = await AjaxService.call(request);
        return response;
    },
};

export default ActivitiesController;
