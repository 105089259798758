import { AjaxService, createEmptyRequest, IAPIRequest, IAPIResponse } from "../Internal/AjaxService";

const InboundController = {
    getCountries: async () => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = "/inbound/countries";
        let response: IAPIResponse = await AjaxService.call(request);
        if (response.error) throw new Error(response.error);
        return new Map<string, string>(Object.entries(response.payload));
    },
};

export default InboundController;
