import { AjaxService, createEmptyRequest, IAPIRequest, IAPIResponse } from "../Internal/AjaxService";

export interface IntegrationHealthCheck {
    ok: boolean;
    message: string;
}

const IntegrationsController = {
    xwareHealthCheck: async () => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = "/integrations/xware-health-check";
        let response: IAPIResponse = await AjaxService.call(request);
        if (response.error != null) throw new Error(response.error);
        return response.payload as IntegrationHealthCheck;
    },
    lakeHealthCheck: async () => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = "/integrations/docware-lake-health-check";
        let response: IAPIResponse = await AjaxService.call(request);
        if (response.error != null) throw new Error(response.error);
        return response.payload as IntegrationHealthCheck;
    },
    cognigeoHealthCheck: async () => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = "/integrations/cognigeo-health-check";
        let response: IAPIResponse = await AjaxService.call(request);
        if (response.error != null) throw new Error(response.error);
        return response.payload as IntegrationHealthCheck;
    },
};

export default IntegrationsController;
