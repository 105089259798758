import "./Toolbar.scss";

import { useNavigate } from "react-router-dom";
import _ from "underscore";

import { ICommandBarItemProps } from "@fluentui/react";

import AppRoutes from "../../Constants/AppRoutes";
import { useLoaderContext } from "../../Context/LoaderContext";
import { useModalContext } from "../../Context/ModalContext";
import { BusinessObjectStatus } from "../../Models/BusinessObject";
import ApiService from "../../Services/ApiService";
import { AjaxService, APISettings } from "../../Services/Internal/AjaxService";
import useSelectionStore from "../../Stores/SelectionStore";
import WindowToast from "../../Utils/WindowToast";
import StyledCommandBar from "./StyledCommandBar";
import { queryClient } from "@/ProviderWrapper";

export interface WikiListToolbarProps {
    // currentFile?: IBaseFile;
    // selectedFiles?: IBaseFile[];
    // reloadPage?: () => void;
}

export const WikiListToolbar = (props: WikiListToolbarProps) => {
    const navigate = useNavigate();

    const modal = useModalContext();
    const loader = useLoaderContext();

    const selection = useSelectionStore((state) => state.selection);
    const setSelection = useSelectionStore((state) => state.setSelection);

    const deleteMany = async () => {
        loader?.show();

        await Promise.all(
            selection.map((file) =>
                ApiService.BusinessObjectsController.deletePublishedDescription(file.id).catch(WindowToast.error)
            )
        )
            .then(async () => {
                setSelection([]);
                WindowToast.success("Descriptions deleted successfully");
                await queryClient.invalidateQueries({ queryKey: ["wikiListBusinessObjects"] });
            })
            .catch(WindowToast.error)
            .finally(loader?.hide);
    };

    let items: ICommandBarItemProps[] = [];

    items.push({
        key: "home",
        text: "Home",
        iconProps: { iconName: "Home" },
        onClick: () => {
            navigate(AppRoutes.HOME);
        },
    });

    if (selection.length > 0) {
        items.push(
            {
                key: "export",
                text: "Export",
                iconProps: { iconName: "ExcelDocument" },
                onClick: () => {
                    const ids = selection.map((x) => x.id).join("&ids=");
                    loader?.show();
                    AjaxService.downloadFileFromUrl(
                        `${APISettings.baseUrl}/excel/business-objects?ids=${ids}`,
                        "business_objects.xlsx"
                    );
                    loader?.hide();
                },
            },
            {
                key: "delete",
                text: "Delete",
                iconProps: { iconName: "Delete" },
                onClick: () => {
                    modal?.showConfirmModal(
                        "Delete description",
                        "Delete published description for selected business objects?",
                        deleteMany
                    );

                    //call delete for
                    // homeSelection.map((x) => x.id);
                },
            }
        );
    }

    const farItems: ICommandBarItemProps[] = [];

    return <StyledCommandBar commandBarProps={{ items, farItems }} />;
};
