import StatusBubble, { StatusBubbleProps } from "./StatusBubble";

export enum ServiceStatusTypes {
    NotAvailable,
    Available,
    Loading,
    Unknown,
}

const serviceStatusItems: StatusBubbleProps[] = [
    { label: "Not available", iconName: "", color: "#FFFFFF", backgroundColor: "#D83B01" },
    { label: "Available", iconName: "Checkmark", color: "#FFFFFF", backgroundColor: "#107C10" },
    { label: "Loading", iconName: "", color: "#FFFFFF", backgroundColor: "#EF6900" },
    { label: "Unknown", iconName: "", color: "#FFFFFF", backgroundColor: "#EF6900" },
];

const ServiceStatusBubble = ({ status }: { status: number }) => {
    return <StatusBubble {...serviceStatusItems[status]} />;
};

export default ServiceStatusBubble;
