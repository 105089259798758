import "./Toolbar.scss";

import { useNavigate, useParams } from "react-router-dom";
import _ from "underscore";

import { useLoaderContext } from "../../Context/LoaderContext";
import { useModalContext } from "../../Context/ModalContext";
import useToolbarStore, { ToolbarType } from "../../Stores/ToolbarStore";
import useUserStore from "../../Stores/UserStore";
import { AdminToolbar } from "./AdminToolbar";
import { DetailEditToolbar } from "./DetailEditToolbar";
import { DetailInfoToolbar } from "./DetailInfoToolbar";
import { DetailReadToolbar } from "./DetailReadToolbar";
import { HomeToolbar } from "./HomeToolbar";
import { LakeJobsToolbar } from "./LakeJobsToolbar";
import { WikiListToolbar } from "./WikiListToolbar";
import StyledCommandBar from "./StyledCommandBar";
import { DevToolbar } from "./DevToolbar";

interface ToolbarProps {
    type: ToolbarType | null;
}

export const Toolbar = ({ type }: ToolbarProps) => {
    const navigate = useNavigate();

    const user = useUserStore((state) => state.user);

    const loader = useLoaderContext();
    const modal = useModalContext();

    const { id: businessObjectId } = useParams();

    if (user === undefined) {
        return null;
    }

    let commandBar: JSX.Element | null = null;

    switch (type) {
        case ToolbarType.Home:
            commandBar = <HomeToolbar />;
            break;
        case ToolbarType.DetailEdit:
            commandBar = <DetailEditToolbar />;
            break;
        case ToolbarType.DetailRead:
            commandBar = <DetailReadToolbar />;
            break;
        case ToolbarType.DetailInfo:
            commandBar = <DetailInfoToolbar />;
            break;
        case ToolbarType.Admin:
            commandBar = <AdminToolbar />;
            break;
        case ToolbarType.WikiList:
            commandBar = <WikiListToolbar />;
            break;
        case ToolbarType.Services:
            commandBar = <AdminToolbar />;
            break;
        case ToolbarType.LakeJobs:
            commandBar = <LakeJobsToolbar />;
            break;
        case ToolbarType.Dev:
            commandBar = <DevToolbar />;
            break;
        default:
            commandBar = <StyledCommandBar commandBarProps={{ items: [], farItems: [] }} />;
            break;
    }

    return commandBar;
};
