import "./ErrorLog.scss";

import _ from "underscore";

import { errorLogTableColumns } from "./ErrorLogTableColumns";
import { Params, useLoaderData } from "react-router-dom";
import GenericList from "../../../Components/GenericList/GenericList";
import ApiService from "../../../Services/ApiService";
import { useQuery } from "@tanstack/react-query";
import Loader from "@/Components/Loader/Loader";
import ErrorPage from "@/Components/ErrorPage/ErrorPage";

export interface ErrorLog {
    id: string;
    procedureLocation: string;
    procedureName: string;
    date: string;
    message: string;
    exception: string;
}

export function ErrorLog() {
    const {
        data: errorLogs,
        isLoading,
        error,
    } = useQuery({
        queryKey: ["errorLogs"],
        queryFn: () => ApiService.LogsController.getErrorLogs(),
    });

    if (isLoading) return <Loader display={true} />;
    if (error) {
        let message = (error as Error | undefined)?.message;
        if (message)
            return <ErrorPage title={"An error occured inside ErrorLog tab"} message={message} page="ErrorLog" />;
    }

    return (
        <div>
            <GenericList
                allowFilter={true}
                searchText={"Search logs"}
                items={errorLogs!}
                columns={errorLogTableColumns}
            />
        </div>
    );
}
