import { useEffect, useState } from "react";
import { defer, useLoaderData, useRouteError } from "react-router-dom";

import ErrorPage from "../../../Components/ErrorPage/ErrorPage";
import GenericList from "../../../Components/GenericList/GenericList";
import ApiService from "../../../Services/ApiService";
import useToolbarStore, { ToolbarType } from "../../../Stores/ToolbarStore";
import { WikiServicesTableColumns } from "./WikiServicesTableColumns";
import { useQuery } from "@tanstack/react-query";
import CustomList from "@/Components/GenericList/CustomList";
import { ServiceStatusTypes } from "@/Components/StatusBubble/ServiceStatusBubble";
import Loader from "@/Components/Loader/Loader";

export function WikiServices() {
    const {
        data: xwareHealthCheck,
        isError: isXwareError,
        isLoading: isXwareLoading,
    } = useQuery({
        queryKey: ["xwareHealthCheck"],
        queryFn: () => ApiService.IntegrationsController.xwareHealthCheck(),
        refetchInterval: 30_000,
    });
    const {
        data: lakeHealthCheck,
        isError: isLakeError,
        isLoading: isLakeLoading,
    } = useQuery({
        queryKey: ["lakeHealthCheck"],
        queryFn: () => ApiService.IntegrationsController.lakeHealthCheck(),
        refetchInterval: 30_000,
    });
    const {
        data: cognigeoHealthCheck,
        isError: isCognigeoError,
        isLoading: isCognigeoLoading,
    } = useQuery({
        queryKey: ["cognigeoHealthCheck"],
        queryFn: () => ApiService.IntegrationsController.cognigeoHealthCheck(),
        refetchInterval: 30_000,
    });

    const setToolbarType = useToolbarStore((state) => state.setType);

    useEffect(() => {
        document.title = "Wiki BO - Wiki Services";
        setToolbarType(ToolbarType.Admin);
    }, []);

    const xwareStatus = isXwareLoading
        ? ServiceStatusTypes.Loading
        : isXwareError
        ? ServiceStatusTypes.Unknown
        : Number(xwareHealthCheck!.ok);

    const cognigeoStatus = isCognigeoLoading
        ? ServiceStatusTypes.Loading
        : isCognigeoError
        ? ServiceStatusTypes.Unknown
        : Number(cognigeoHealthCheck!.ok);

    const lakeStatus = isLakeLoading
        ? ServiceStatusTypes.Loading
        : isLakeError
        ? ServiceStatusTypes.Unknown
        : Number(lakeHealthCheck!.ok);

    const items = [
        { name: "Xware", status: xwareStatus },
        { name: "Cognigeo", status: cognigeoStatus },
        { name: "AI Services", status: lakeStatus },
    ];

    return <CustomList columns={WikiServicesTableColumns} items={items} />;
}
