import { createBrowserRouter, createRoutesFromElements, Navigate, Outlet, Route } from "react-router-dom";

import { AppLayout } from "./AppLayout";
import AppRoutes from "./Constants/AppRoutes";
import { Users } from "./Pages/Admin/Users/Users";
import { WikiList } from "./Pages/Admin/WikiList/WikiList";
import { LakeJobs } from "./Pages/Admin/LakeJobs/LakeJobs";
import { ErrorLog } from "./Pages/Dev/ErrorLog/ErrorLog";
import { EmaiLog } from "./Pages/Dev/EmailLog/EmailLog";
import { WikiServices } from "./Pages/Admin/WikiServices/WikiServices";

const routes = createRoutesFromElements(
    <Route path="/docware-wiki">
        {/* authenticated paths */}
        {/* TODO wrap in user context */}
        <Route path="" element={<AppLayout />}>
            {/* <Route index element={<Navigate to={AppRoutes.HOME} replace />} /> */}
            <Route index lazy={() => import("./Pages/Home/Home")} />
            <Route
                path="detail/:id/*"
                id="detail"
                lazy={() => import("./Pages/Detail/DetailLayout")}
                // only revalidate if the submission originates from
                // the `/docware-wiki/home` route.
                shouldRevalidate={({ currentUrl }) => {
                    if (currentUrl.pathname.includes("/detail/") && currentUrl.pathname.includes("/edit")) {
                        // && !currentUrl.pathname.includes("/edit")) {
                        return true;
                    }
                    // return currentUrl.pathname === "/docware-wiki/home";
                    return false;
                }}
            />

            {/* admin authorized paths */}
            <Route path="admin" lazy={() => import("./Pages/Admin/AdminLayout")}>
                <Route path="users" element={<Users />} />
                <Route path="wiki-list" element={<WikiList />} />
                <Route path="lake-jobs" element={<LakeJobs />} />
                <Route path="services" element={<WikiServices />} />
            </Route>
            <Route path="dev" lazy={() => import("./Pages/Dev/DevLayout")}>
                <Route index element={<Navigate to={"error-logs"} />} />
                <Route path="error-logs" element={<ErrorLog />} />
                <Route path="email-logs" element={<EmaiLog />} />
            </Route>
        </Route>
        {/* unauthenticated paths */}
        <Route path="unauthorized" lazy={() => import("./Pages/Unauthorized/Unauthorized")} />
        <Route path="*" lazy={() => import("./Pages/NotFound/NotFound")} />
    </Route>
);

export default createBrowserRouter(routes);
