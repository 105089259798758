import "./Toolbar.scss";

import { useNavigate, useRouteLoaderData } from "react-router-dom";
import _ from "underscore";

import { Dropdown, ICommandBarItemProps, IDropdownOption, Stack } from "@fluentui/react";

import WindowToast from "../../Utils/WindowToast";
import StyledCommandBar from "./StyledCommandBar";
import AppRoutes from "../../Constants/AppRoutes";
import { useLoaderContext } from "../../Context/LoaderContext";
import ApiService from "../../Services/ApiService";
import useLakeJobsToolbarStore from "../../Stores/LakeJobsToolbarStore";
import { LakeTask, LakeTaskStatusType } from "../../Models/LakeTask";
import { ProfessionalArea } from "../../Models/ProfessionalArea";
import ModalDialog from "../ModalDialog/ModalDialog";
import { CommonModalButtons } from "../../Utils/ModalUtils";
import { useState } from "react";
import { LakeJobsQueryKeys } from "@/Pages/Admin/LakeJobs/LakeJobs";
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "@/ProviderWrapper";
import { Country } from "@/Models/User";

export interface LakeJobsToolbarProps {}

export const LakeJobsToolbar = (props: LakeJobsToolbarProps) => {
    const [[, professionalAreas]] = queryClient.getQueriesData<ProfessionalArea[]>([
        LakeJobsQueryKeys.professionalAreas,
    ]);
    const [[, countries]] = queryClient.getQueriesData<Map<string, string>>([LakeJobsQueryKeys.countries]);

    // console.log(professionalAreas, countries)

    const [showInfoModal, setShowInfoModal] = useState(false);
    const [showNewJobModal, setShowNewJobModal] = useState(false);

    const [selectedCountryKeys, setSelectedCountryKeys] = useState<string[]>([]);
    const [selectedDocumentSubTypeKeys, setSelectedDocumentSubTypeKeys] = useState<string[]>([]);
    const [selectedProfessionalAreaKeys, setSelectedProfessionalAreaKeys] = useState<string[]>([]);

    const navigate = useNavigate();
    const loader = useLoaderContext();
    const selection = useLakeJobsToolbarStore((state) => state.selection);

    if (countries === undefined || professionalAreas === undefined) {
        return null;
    }

    const parseOption = (prevKeys: string[], option: IDropdownOption<any>) => {
        return option.selected ? [...prevKeys, option.key as string] : prevKeys.filter((key) => key !== option.key);
    };

    const countryOptions = Array.from(countries!)
        .map(([key, text]) => ({ key, text }))
        .sort((a, b) => a.text.localeCompare(b.text));

    const professionalAreaOptions = professionalAreas!
        .map((x) => ({
            key: x.id,
            text: x.name,
        }))
        .sort((a, b) => a.text.localeCompare(b.text));

    const documentSubTypeOptions = professionalAreas!
        .filter((x) => selectedProfessionalAreaKeys.includes(x.id))
        .flatMap((x) => x.documentTypes)
        .flatMap((x) => x.subTypes)
        .map((x) => ({ key: x.id, text: x.name }))
        .sort((a, b) => a.text.localeCompare(b.text));

    const closeModal = () => {
        setShowNewJobModal(false);
        setSelectedCountryKeys([]);
        setSelectedProfessionalAreaKeys([]);
        setSelectedDocumentSubTypeKeys([]);
    };

    const sendRequest = () => {
        loader?.show();
        ApiService.DocwareLakeController.newJob({
            countries: selectedCountryKeys,
            professionalAreas: selectedProfessionalAreaKeys,
            documentSubtypes: selectedDocumentSubTypeKeys,
        })
            .then((res) => {
                if (res.error !== null) throw new Error("Error creating job");
                WindowToast.success("New job created successfully");
                const task = res.payload as LakeTask;
                // setTasks((prev) => [task, ...prev]);
            })
            .catch(WindowToast.error)
            .finally(loader?.hide);
    };

    const professionalAreasToSubtypes = (
        totalProfessionalAreas: ProfessionalArea[],
        selectedProfessionalAreas: string[]
    ) => {
        let subTypesWithDuplicates = totalProfessionalAreas
            .filter((x) => selectedProfessionalAreas.includes(x.id))
            .flatMap((x) => x.documentTypes)
            .flatMap((x) => x.subTypes);

        let uniqueSubtypes = subTypesWithDuplicates.filter(
            (obj, index) => index === subTypesWithDuplicates.findIndex((x) => obj.id === x.id)
        );
        return uniqueSubtypes;
    };

    let items: ICommandBarItemProps[] = [];

    if (selection.length === 0) {
        items = [
            {
                key: "home",
                text: "Home",
                iconProps: { iconName: "Home" },
                onClick: () => {
                    navigate(AppRoutes.HOME);
                },
            },
        ];
    }

    if (selection.length === 1) {
        const task = _.first(selection)!;
        items = [
            {
                key: "infoToolbar",
                text: "Info",
                iconProps: { iconName: "Info" },
                onClick: () => {
                    setShowInfoModal(true);
                },
            },
            {
                key: "duplicateToolbar",
                text: "Duplicate",
                iconProps: { iconName: "Copy" },
                onClick: () => {
                    if (task.filters === null) {
                        WindowToast.error("Task query is null. Cannot perfor operation");
                    } else {
                        setSelectedCountryKeys(task.filters.countries);
                        setSelectedProfessionalAreaKeys(task.filters.professional_areas);
                        setSelectedDocumentSubTypeKeys(task.filters.document_subtypes);

                        setShowNewJobModal(true);
                    }
                },
            },
            {
                key: "trashToolbar",
                text: "Move to trash",
                iconProps: { iconName: "Delete" },
                disabled:
                    task.status === LakeTaskStatusType.Completed || task.status === LakeTaskStatusType.Unterminated,
                onClick: () => {
                    loader?.show();
                    ApiService.DocwareLakeController.terminateJob(task.taskId)
                        .then((res) => {
                            if (res.error !== null) throw res.error;
                            WindowToast.success("Job terminated successfully");
                        })
                        .catch(WindowToast.error)
                        .finally(loader?.hide);
                },
            },
            {
                key: "restartToolbar",
                text: "Restart",
                iconProps: { iconName: "ArrowRepeatAll" },
                disabled:
                    task.status === LakeTaskStatusType.Completed || task.status === LakeTaskStatusType.Unterminated,
                onClick: () => {
                    loader?.show();
                    ApiService.DocwareLakeController.restartJob(task.taskId)
                        .then((res) => {
                            if (res.error !== null) throw res.error;
                            WindowToast.success("Job restarted successfully");
                        })
                        .catch(WindowToast.error)
                        .finally(loader?.hide);
                },
            },
        ];
    }

    const farItems: ICommandBarItemProps[] = [];

    return (
        <>
            <StyledCommandBar commandBarProps={{ items, farItems }} />
            <ModalDialog
                enableModal={showInfoModal}
                modalTitle="Job parameteres"
                modalInnerComponent={
                    selection.length === 1 && (
                        <Stack>
                            <span>
                                Countries:{" "}
                                {Array.from(countries!.entries())
                                    .filter(([key, name]) => _.first(selection)!.filters.countries.includes(key))
                                    .map(([key, name]) => name)
                                    .join(", ")}
                            </span>
                            <span>
                                Professional Areas:{" "}
                                {professionalAreas!
                                    .filter((x) => _.first(selection)!.filters.professional_areas.includes(x.id))
                                    .map((x) => x.name)
                                    .join(", ")}
                            </span>
                            <span>
                                Document Subtypes:{" "}
                                {professionalAreasToSubtypes(
                                    professionalAreas!,
                                    _.first(selection)!.filters.professional_areas
                                )
                                    .filter((x) => _.first(selection)!.filters.document_subtypes.includes(x.id))
                                    .map((x) => x.name)
                                    .join(", ")}
                            </span>
                        </Stack>
                    )
                }
                modalButtons={CommonModalButtons.ok({
                    onAccept: () => setShowInfoModal(false),
                })}
                onAbort={() => setShowInfoModal(false)}
            />

            <ModalDialog
                enableModal={showNewJobModal}
                modalTitle="New Job"
                modalInnerComponent={
                    <Stack>
                        <Dropdown
                            label="Country"
                            options={countryOptions}
                            multiSelect
                            onChange={(event, option) => {
                                if (option !== undefined) {
                                    setSelectedCountryKeys((prev) => parseOption(prev, option));
                                }
                            }}
                            selectedKeys={selectedCountryKeys}
                        />
                        <Dropdown
                            label="Professional Area"
                            options={professionalAreaOptions}
                            multiSelect
                            onChange={(event, option) => {
                                if (option !== undefined) {
                                    setSelectedProfessionalAreaKeys((prev) => parseOption(prev, option));
                                }
                            }}
                            selectedKeys={selectedProfessionalAreaKeys}
                        />
                        <Dropdown
                            label="Document Subtype"
                            options={documentSubTypeOptions}
                            multiSelect
                            onChange={(event, option) => {
                                if (option !== undefined) {
                                    setSelectedDocumentSubTypeKeys((prev) => parseOption(prev, option));
                                }
                            }}
                            selectedKeys={selectedDocumentSubTypeKeys}
                            disabled={selectedProfessionalAreaKeys.length === 0}
                        />
                    </Stack>
                }
                onAbort={closeModal}
                modalButtons={[
                    {
                        label: "Cancel",
                        onClick: () => {
                            closeModal();
                        },
                    },
                    {
                        label: "Send Request",
                        onClick: () => {
                            sendRequest();
                            closeModal();
                        },
                        disabled:
                            selectedCountryKeys.length === 0 ||
                            selectedProfessionalAreaKeys.length === 0 ||
                            selectedDocumentSubTypeKeys.length === 0,
                    },
                ]}
            />
        </>
    );
};
