export default interface User {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
	creationDate: Date;
	roles: Set<UserRole>;
	countries: Map<string, string>
}

export interface Country{
	id: string;
	name: string;
}

export enum UserRole {
	Reader,
	Writer,
	Approver,
	Admin,
	Developer = 9000
}

export const userRoleNameMap = [
	{ key: UserRole.Reader, name: "Reader" },
	{ key: UserRole.Writer, name: "Writer" },
	{ key: UserRole.Approver, name: "Approver" },
	{ key: UserRole.Admin, name: "Admin" },
];

export interface BaseUser {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
}