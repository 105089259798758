import { BusinessObjectType } from "@/Models/BusinessObject";
import { AjaxService, createEmptyRequest, IAPIRequest, IAPIResponse } from "../Internal/AjaxService";
import { LakeTask } from "@/Models/LakeTask";
import { LakeJob } from "@/Models/LakeJob";

export interface newJobPayload {
    countries: string[];
    documentSubtypes: string[];
    professionalAreas: string[];
}

const DocwareLakeController = {
    getBusinessObjectWithContents: async (businessObjectCode: string, businessObjectType: BusinessObjectType) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = `/docwareLake?businessObjectCode=${businessObjectCode}&businessObjectType=${businessObjectType}`;
        let response: IAPIResponse = await AjaxService.call(request);
        if (response.error != null) throw new Error(response.error);
        if (response.payload === null) return null;
        const lakeJob = response.payload as LakeJob;
        return {
            ...lakeJob,
            contents: lakeJob.contents.map((x) => ({ ...x, generatedOn: new Date(x.generatedOn) })),
        } as LakeJob;
    },
    getTasks: async () => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = `/docwareLake/tasks`;
        let response: IAPIResponse = await AjaxService.call(request);
        if (response.error != null) throw new Error(response.error);
        return response.payload as LakeTask[];
    },
    newJob: async (payload: newJobPayload) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = `/docwareLake/new`;
        request.method = "POST";
        request.payload = payload;
        let response: IAPIResponse = await AjaxService.call(request);
        return response;
    },
    restartJob: async (taskId: string) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = `/docwareLake/${taskId}`;
        request.method = "POST";
        let response: IAPIResponse = await AjaxService.call(request);
        return response;
    },
    terminateJob: async (taskId: string) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = `/docwareLake/${taskId}`;
        request.method = "DELETE";
        let response: IAPIResponse = await AjaxService.call(request);
        return response;
    },
};

export default DocwareLakeController;
