import { TooltipHost } from "@fluentui/react";

import StatusBubble from "../../../Components/StatusBubble/StatusBubble";
import { DEFAULT_LOCALE } from "../../../Constants/Constants";
import BusinessObject, { BusinessObjectStatus, BusinessObjectTypeName } from "../../../Models/BusinessObject";
import BOStatusBubble from "../../../Components/StatusBubble/BOStatusBubble";

export const WikiListTableColumns: any[] = [
    {
        name: "BO countries",
        dataType: "string",
        fieldName: "countries",
        size: "medium",
        onRender: (bo: BusinessObject) => {
            const countries = [...bo.countries.values()].join(", ");
            return <TooltipHost content={countries}>{countries}</TooltipHost>;
        },
    },
    {
        name: "BO type",
        dataType: "string",
        fieldName: "type",
        size: "little",
        onRender: (bo: BusinessObject) => BusinessObjectTypeName[bo.type],
    },
    {
        name: "BO code",
        dataType: "string",
        fieldName: "code",
        size: "moderate",
    },
    {
        name: "BO name",
        dataType: "string",
        fieldName: "name",
        size: "large",
    },
    {
        name: "Published date",
        dataType: "date",
        fieldName: "publishDate",
        size: "medium",
        onRender: (bo: BusinessObject) =>
            bo.publishDate === null ? "--" : bo.publishDate.toLocaleString(DEFAULT_LOCALE),
    },
    {
        name: "Publisher",
        fieldName: "publisher",
        size: "medium",
        onRender: (bo: BusinessObject) =>
            bo.publisher === null ? "--" : `${bo.publisher.firstName} ${bo.publisher.lastName}`,
    },
    {
        name: "Last update",
        dataType: "date",
        fieldName: "updatedDate",
        size: "medium",
        onRender: (bo: BusinessObject) =>
            bo.updatedDate === null ? "--" : bo.updatedDate.toLocaleString(DEFAULT_LOCALE),
    },
    {
        name: "User",
        fieldName: "",
        size: "medium",
        onRender: (bo: BusinessObject) =>
            bo.updatedUser === null ? "--" : `${bo.updatedUser.firstName} ${bo.updatedUser.lastName}`,
    },
    {
        name: "Status",
        dataType: "string",
        fieldName: "",
        size: "little",
        onRender: (bo: BusinessObject) =>
            bo.status === BusinessObjectStatus.NotPresent ? "" : <BOStatusBubble status={bo.status} />,
    },
];
