import "./Toolbar.scss";

import { useNavigate, useParams } from "react-router-dom";
import _ from "underscore";

import { ICommandBarItemProps } from "@fluentui/react";

import AppRoutes from "../../Constants/AppRoutes";
import useUserStore from "../../Stores/UserStore";
import WindowToast from "../../Utils/WindowToast";
import StyledCommandBar from "./StyledCommandBar";

export interface DetailInfoToolbarProps {}

export const DetailInfoToolbar = (props: DetailInfoToolbarProps) => {
    const navigate = useNavigate();

    const { id: businessObjectId } = useParams();

    const user = useUserStore((state) => state.user);

    if (user === undefined) {
        return null;
    }

    const items: ICommandBarItemProps[] = [
        {
            key: "home",
            text: "Home",
            iconProps: { iconName: "Home" },
            onClick: () => {
                navigate(AppRoutes.HOME);
            },
        },
        {
            key: "description-editor",
            text: "Back To Editor",
            iconProps: { iconName: "TextDescription" },
            onClick: () => {
                if (businessObjectId === undefined) {
                    WindowToast.warning("Wrong business object id");
                    return;
                } else {
                    navigate(AppRoutes.DETAIL_EDIT.replace(":id", businessObjectId));
                }
            },
        },
    ];
    const farItems: ICommandBarItemProps[] = [];

    return <StyledCommandBar commandBarProps={{ items, farItems }} />;

};
