import User, { UserRole } from "../../Models/User";
import { AjaxService, createEmptyRequest, IAPIRequest, IAPIResponse } from "../Internal/AjaxService";

const USER_URLS = {
    SELF: "/users",
    BY_ID: "/users/{userId}",
    UPDATE_ROLES: "/users/{userId}/roles",
    USERS: "/users/users",
    GRAPH_USERS: "/users/graph-users?search={search}",
};

const UsersController = {
    getSelf: async (callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = USER_URLS.SELF;
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    getUsers: async (callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = USER_URLS.USERS;
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    getGraphUsers: async (search: string, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = USER_URLS.GRAPH_USERS.replace("{search}", search);
        request.method = "GET";
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    insertUserFromId: async (userId: string, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = USER_URLS.BY_ID.replace("{userId}", userId);
        request.method = "POST";
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    insertUser: async (user: User, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = USER_URLS.BY_ID;
        request.method = "POST";
        request.payload = user;
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    updateRole: async (userId: string, roles: Set<UserRole>, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = USER_URLS.UPDATE_ROLES.replace("{userId}", userId);
        request.method = "POST";
        request.payload = [...roles];
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    deleteUser: async (userId: string, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = USER_URLS.BY_ID.replace("{userId}", userId);
        request.method = "DELETE";
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    logout: async (callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = "/users/logout";
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    syncPermissions: async (callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = "/users/sync";
        request.method = "POST";
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
};

export default UsersController;
