import "./AppLayout.scss";

import { useEffect, useState } from "react";
import { Outlet, ScrollRestoration, useNavigate, useNavigation, useSearchParams } from "react-router-dom";

import { AuthenticationHelper, GlobalAPIMessageHandler, SharedState } from "@Eni/docware-fe-master";

import Loader from "./Components/Loader/Loader";
import { Toolbar } from "./Components/Toolbar/Toolbar";
import AppRoutes from "./Constants/AppRoutes";
import { useLoaderContext } from "./Context/LoaderContext";
import User from "./Models/User";
import ApiService from "./Services/ApiService";
import { IAPIResponse } from "./Services/Internal/AjaxService";
import useToolbarStore, { ToolbarType } from "./Stores/ToolbarStore";
import useUserStore from "./Stores/UserStore";

export const enhanceAppTitle = (title: string | null) => {
    let futureTitle = "Wiki BO";

    if (title) {
        futureTitle = "Wiki BO - " + title;
    }

    if (document.title !== futureTitle) {
        document.title = futureTitle;
    }
};

export const AppLayout = () => {
    const [badError, setBadError] = useState<boolean>(false);

    const navigate = useNavigate();
    const { state } = useNavigation();

    const loader = useLoaderContext();

    const user = useUserStore((state) => state.user);
    const setUser = useUserStore((state) => state.setUser);

    const toolbarType = useToolbarStore((state) => state.type);

    const [waitingLogin, setWaitingLogin] = useState(false);

    const postLoginProcess = () => {
        setWaitingLogin(true);
        ApiService.UsersController.getSelf()
            .then((response: IAPIResponse) => {
                if (response.error !== null) {
                    if (response.raw.status === 403) {
                        navigate(AppRoutes.UNAUTHORIZED);
                    }
                } else {
                    let user = response.payload as User;
                    const roles = new Set(user.roles);
                    if (roles.size === 0) {
                        navigate(AppRoutes.UNAUTHORIZED);
                    }
                    user = {
                        ...user,
                        roles,
                        countries: new Map<string, string>(Object.entries(user.countries)),
                    };
                    setUser(user);
                    // if (user.countries.length === 0){
                    //     WindowToast.warning("You do not have permissions on any country")
                    // }
                }
            })
            .finally(() => {
                setWaitingLogin(false);
            });
    };

    /**
     * What should happen when the login procedure fails
     */
    function failedLoginProcess(e: any) {
        console.error(e);
        console.error("[Auth] Failed login process");
        setUser(undefined);
        setBadError(true);
        setWaitingLogin(false);
    }

    const startLoginRoutine = async () => {
        setWaitingLogin(false);
        AuthenticationHelper.startLoginRoutine().catch((e) => {
            console.error(e);
            failedLoginProcess(e);
        });
    };

    useEffect(() => {
        if (localStorage.getItem("do-not-start-the-login") === "true") {
            /** define with this call what will be visible in the MESSAGE_PAGE */
            // setPageContentState({
            //     title: "Logged out!",
            //     subtitle: "Is a good idea to close all docware tabs to complete the logout process.",
            //     imageName: "logoutDog",
            //     button: null,
            // });

            setWaitingLogin(false);
            localStorage.removeItem("do-not-start-the-login");
        } else {
            loader?.show();
            SharedState.authStatus.subscribe((status) => {
                console.debug("[auth] SharedState.authStatus subscribe. status: ", status);
                if (status.error) {
                    failedLoginProcess(status.error?.errorDetails);
                    return;
                }
                if (status.accessToken) {
                    postLoginProcess();
                } else {
                    if (!status.pending) {
                        startLoginRoutine();
                    }
                }
                loader?.hide();
            });
        }
    }, []);

    return (
        <div style={{ overflow: "hidden" }}>
            {state === "loading" && <Loader display={true} />}
            {waitingLogin && <Loader display={true} text="Working with Eni login..." />}
            {!waitingLogin && user !== undefined && (
                <>
                    <Toolbar type={toolbarType} />
                    <GlobalAPIMessageHandler />
                    <ScrollRestoration />
                    <div className="page-wrap">
                        <Outlet />
                    </div>
                </>
            )}
        </div>
    );
};
