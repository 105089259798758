const BASE = "/docware-wiki";

const HOME = `${BASE}/`;

const DETAIL = `${BASE}/detail/:id`;
const DETAIL_READ = `${BASE}/detail/:id/read`;
const DETAIL_EDIT = `${BASE}/detail/:id/edit`;
const DETAIL_INFO = `${BASE}/detail/:id/info`;

const ADMIN = `${BASE}/admin`;
const ADMIN_USERS = `${ADMIN}/users`;

const UNAUTHORIZED = `${BASE}/unauthorized`;

const AppRoutes = {
    BASE,
    HOME,
	DETAIL,
    DETAIL_READ,
    DETAIL_EDIT,
    DETAIL_INFO,
    ADMIN,
    ADMIN_USERS,
    UNAUTHORIZED,
};

export default AppRoutes;
