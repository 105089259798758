import { CommandBar, IButtonProps, ICommandBarProps, Spinner } from "@fluentui/react";

type StyledCommandBarProps = {
    commandBarProps: Omit<
        ICommandBarProps,
        "ariaLabel" | "overflowButtonProps" | "primaryGroupAriaLabel" | "farItemsGroupAriaLabel" | "styles"
    >;
    isLoading?: boolean;
};
const overflowProps: IButtonProps = { ariaLabel: "More commands" };

//some leftover code if in teh future I am asked to implement loading (I tried enough but position is fucked)
export default function StyledCommandBar({ commandBarProps, isLoading }: StyledCommandBarProps) {
    return (
        // <div className="toolbar-wrapper">
        //     {loading ? (
        //         <Spinner label="Loading..." labelPosition="right" />
        //     ) : (

        <CommandBar
            {...commandBarProps}
            items={
                isLoading === true
                    ? [
                          {
                              key: "loading-button",
                              text: "Loading...",
                              iconProps: { iconName: "ProgressRingDots" },
                          },
                      ]
                    : commandBarProps.items
            }
            overflowButtonProps={overflowProps}
            ariaLabel="Wiki BO actions"
            primaryGroupAriaLabel="User actions"
            farItemsGroupAriaLabel="More actions"
            styles={{ root: { borderBottom: "1px solid rgba(0, 0, 0, 0.2)" } }}
        />

        //     )}
        // </div>
    );
}
