import { create } from "zustand";

export enum ToolbarType {
	Home,
	HomeSelction,
	DetailRead,
	DetailEdit,
	DetailInfo,
	Admin,
	WikiList,
	LakeJobs,
	Services,
	Dev
}

export interface ToolbarState {
	type: ToolbarType | null;
	setType: (type: ToolbarType | null) => void;
}

const useToolbarStore = create<ToolbarState>((set) => ({
	type: null,
	setType: (type: ToolbarType | null) => set((state) => ({ type: type })),
}));

export default useToolbarStore;
