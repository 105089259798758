import { ActionButton, IContextualMenuItem } from "@fluentui/react";

import { DEFAULT_LOCALE } from "../../../Constants/Constants";
import { LakeTask, LakeTaskStatusType } from "../../../Models/LakeTask";
import LakeJobStatusBubble from "../../../Components/StatusBubble/LakeJobStatusBubble";
import { ListSizes, ModifiableIColumn } from "@/Components/GenericList/CustomList";

export interface LakeJobsTableActions {
    info: (item: LakeTask) => void;
    duplicate: (item: LakeTask) => void;
    moveToTrash: (item: LakeTask) => void;
    restart: (item: LakeTask) => void;
}

export const getLakeJobsDetailsColumns = (actions: LakeJobsTableActions): ModifiableIColumn[] => [
    {
        name: "",
        fieldName: "",
        iconName: "MoreCircle",
        minWidth: ListSizes.micro.minWidth,
        maxWidth: ListSizes.micro.maxWidth,
        onRender: (task: LakeTask) => (
            <ActionButton
                iconProps={{ iconName: "MoreCircle" }}
                menuProps={{
                    items: [
                        {
                            key: "info",
                            text: "Info",
                            iconProps: { iconName: "Info" },
                            onClick: (event, contextualItem) => actions.info(task),
                        },
                        {
                            key: "duplicate",
                            text: "Duplicate",
                            iconProps: { iconName: "Copy" },
                            onClick: (event, contextualItem) => actions.duplicate(task),
                        },
                        {
                            key: "moveToTrash",
                            text: "Move to trash",
                            iconProps: { iconName: "Delete" },
                            onClick: (event, contextualItem) => actions.moveToTrash(task),
                            disabled:
                                task.status === LakeTaskStatusType.Completed ||
                                task.status === LakeTaskStatusType.Unterminated,
                        },
                        {
                            key: "restart",
                            text: "Restart",
                            iconProps: { iconName: "ArrowRepeatAll" },
                            onClick: (event, contextualItem) => actions.restart(task),
                            disabled:
                                task.status === LakeTaskStatusType.Completed ||
                                task.status === LakeTaskStatusType.Unterminated,
                        },
                    ],
                }}
                styles={{ root: { height: 20 }, menuIcon: { display: "none" } }}
            />
        ),
    },
    {
        name: "TaskID",
        data: "string",
        fieldName: "taskId",
        minWidth: ListSizes.medium.minWidth,
        maxWidth: ListSizes.medium.maxWidth,
    },
    {
        name: "Start Date",
        data: "date",
        fieldName: "startDate",
        minWidth: ListSizes.bigger.minWidth,
        maxWidth: ListSizes.bigger.maxWidth,
        onRender: (task: LakeTask) => new Date(task.startDate).toLocaleString(DEFAULT_LOCALE),
    },
    {
        name: "User",
        data: "string",
        fieldName: "user",
        minWidth: ListSizes.bigger.minWidth,
        maxWidth: ListSizes.bigger.maxWidth,
        onRender: (task: LakeTask) => `${task.author.firstName} ${task.author.lastName}`,
    },
    {
        name: "Total",
        data: "number",
        fieldName: "totalDocuments",
        minWidth: ListSizes.moderate.minWidth,
        maxWidth: ListSizes.moderate.maxWidth,
    },
    {
        name: "Unsupported",
        data: "number",
        fieldName: "unsupportedDocuments",
        minWidth: ListSizes.moderate.minWidth,
        maxWidth: ListSizes.moderate.maxWidth,
    },
    {
        name: "Completed",
        data: "number",
        fieldName: "completedDocuments",
        minWidth: ListSizes.moderate.minWidth,
        maxWidth: ListSizes.moderate.maxWidth,
    },
    {
        name: "To Process",
        data: "number",
        fieldName: "documentsToProcess",
        minWidth: ListSizes.moderate.minWidth,
        maxWidth: ListSizes.moderate.maxWidth,
    },
    {
        name: "Failed",
        data: "number",
        fieldName: "failedDocuments",
        minWidth: ListSizes.moderate.minWidth,
        maxWidth: ListSizes.moderate.maxWidth,
    },
    {
        name: "Status",
        data: "string",
        fieldName: "status",
        minWidth: ListSizes.medium.minWidth,
        maxWidth: ListSizes.medium.maxWidth,
        onRender: (task: LakeTask) => <LakeJobStatusBubble status={task.status} />,
    },
];
